<template>
  <div class="login-page">
    <v-container fluid class="fill-height pa-0">
      <v-row no-gutters class="fill-height">
        <!-- Left Panel - Login Form -->
        <v-col cols="12" md="6" class="d-flex align-center justify-center">
          <v-card
            class="login-card mx-auto"
            max-width="450"
            flat
            elevation="0"
          >
            <div class="px-8">
              <div class="mb-12">
                <h1 class="text-h4 font-weight-bold mb-2">Welcome back</h1>
                <p class="text-h6 text-medium-emphasis">
                  Sign in to continue to LegalLogic
                </p>
              </div>

              <!-- Error Alert -->
              <v-alert
                v-if="error"
                type="error"
                class="mb-4"
                closable
                variant="tonal"
                @click:close="clearError"
                density="compact"
              >
                {{ error }}
              </v-alert>

              <!-- Connection Warning -->
              <v-alert
                v-if="!isConnected"
                type="warning"
                class="mb-4"
                icon="mdi-wifi-off"
                variant="tonal"
                density="compact"
              >
                Connection issues detected. Please check your internet connection.
              </v-alert>

              <!-- Google Sign In Button -->
              <v-btn
                block
                color="surface"
                size="large"
                :loading="loading"
                :disabled="!isConnected"
                @click="signInWithGoogle"
                class="sign-in-button mb-6"
                height="48"
              >
                <div class="d-flex align-center justify-center w-100">
                  <img
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                    alt="Google Logo"
                    class="mr-4"
                    width="24"
                    height="24"
                  >
                  <span class="text-body-1">Continue with Google</span>
                </div>
              </v-btn>

              <!-- Help Link -->
              <div class="text-center">
                <v-btn
                  variant="text"
                  color="primary"
                  class="text-none"
                  href="mailto:support@legallogic.com"
                >
                  Need help signing in?
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>

        <!-- Right Panel - Features Carousel -->
        <v-col cols="12" md="6" class="features-panel">
          <div class="overlay-background">
            <v-carousel
              cycle
              height="100%"
              hide-delimiter-background
              show-arrows="hover"
              class="features-carousel"
            >
              <v-carousel-item
                v-for="(feature, i) in features"
                :key="i"
              >
                <div class="feature-content d-flex flex-column align-center justify-center text-center px-16">
                  <v-icon
                    :icon="feature.icon"
                    size="64"
                    color="white"
                    class="mb-6"
                  ></v-icon>
                  <h3 class="text-h4 font-weight-light text-white mb-4">
                    {{ feature.title }}
                  </h3>
                  <p class="text-body-1 text-white">
                    {{ feature.description }}
                  </p>
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import { useAuth } from '../stores/auth'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'

const { 
  error, 
  loading, 
  isConnected,
  signInWithGoogle,
  clearError,
  isAuthenticated
} = useAuth()

const router = useRouter()

// Add authentication check
onMounted(() => {
  if (isAuthenticated) {
    router.push('/library')
  }
})

const features = [
  {
    icon: 'mdi-presentation',
    title: 'Create Compelling Presentations',
    description: 'Build professional legal presentations with our intuitive tools and templates.'
  },
  {
    icon: 'mdi-account-group',
    title: 'Collaborate Seamlessly',
    description: 'Work together with your team in real-time, share documents, and track changes.'
  },
  {
    icon: 'mdi-shield-check',
    title: 'Secure and Compliant',
    description: 'Your data is protected with enterprise-grade security and compliance features.'
  }
]
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  background: rgb(var(--v-theme-background));
  height: 100vh;
  overflow: hidden;
}

.login-card {
  width: 100%;
  padding: 24px;
}

.features-panel {
  background-image: url('/images/homepage_bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.features-carousel {
  height: 100%;
  position: relative;
  z-index: 2;
}

.feature-content {
  height: 100%;
  opacity: 1 !important;
}

:deep(.v-carousel__controls) {
  background: transparent;
}

:deep(.v-btn--icon) {
  background: rgba(255, 255, 255, 0.1);
}

/* Ensure carousel content is fully visible */
:deep(.v-window__container) {
  height: 100%;
}

:deep(.v-window-item) {
  height: 100%;
}

:deep(.v-carousel-item) {
  opacity: 1 !important;
}
</style> 