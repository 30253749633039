<template>
  <div class="not-found">
    <!-- Hero Section -->
    <div class="hero-section">
      <div class="hero-overlay">
        <v-container class="d-flex flex-column align-center justify-center text-center py-16">
          <v-icon
            color="white"
            size="96"
            class="mb-8"
          >
            mdi-gavel
          </v-icon>
          
          <h1 class="text-h2 font-weight-light text-white mb-4">
            404: Case Not Found
          </h1>
          
          <p class="text-h6 font-weight-light text-white mb-12" style="max-width: 600px;">
            The page you're looking for appears to be missing from our records. Let's help you find your way back.
          </p>

          <div class="d-flex gap-4">
            <v-btn
              color="primary"
              size="x-large"
              prepend-icon="mdi-home"
              :to="isAuthenticated ? '/library' : '/'"
              class="px-8"
            >
              {{ isAuthenticated ? 'Go to Library' : 'Go Home' }}
            </v-btn>
            
            <v-btn
              variant="outlined"
              color="white"
              size="x-large"
              prepend-icon="mdi-help-circle"
              to="/documentation"
              class="px-8"
            >
              Get Help
            </v-btn>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuth } from '../stores/auth'

const { isAuthenticated } = useAuth()
</script>

<style scoped>
.not-found {
  min-height: 100vh;
  background: rgb(var(--v-theme-background));
}

.hero-section {
  min-height: 100vh;
  background-image: url('/images/homepage_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-4 {
  gap: 1rem;
}
</style> 