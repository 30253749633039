<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="LegalPad Logo"
    class="logo-icon"
  >
    <path
      d="M13 7.83c.85-.3 1.53-.98 1.83-1.83H18l-3 7c0 1.66 1.57 3 3.5 3s3.5-1.34 3.5-3l-3-7h2V4h-6.17c-.41-1.17-1.52-2-2.83-2s-2.42.83-2.83 2H3v2h2l-3 7c0 1.66 1.57 3 3.5 3s3.5-1.34 3.5-3l-3-7h3.17c.3.85.98 1.53 1.83 1.83V19H2v2h20v-2h-9V7.83zM18.37 8l1.29 3h-2.58l1.29-3zM5.63 8l1.29 3H4.34L5.63 8zM12 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
      fill="#0abab5"
    />
  </svg>
</template>

<style scoped>
.logo-icon {
  transition: transform 0.3s ease;
}

.logo-icon:hover {
  transform: scale(1.1);
}
</style> 