<template>
  <v-container>
    <!-- Header -->
    <div class="d-flex align-center mb-6">
      <div>
        <h1 class="text-h4 font-weight-light">New Presentation</h1>
        <p class="text-medium-emphasis">Create a new presentation</p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="saving"
        @click="handleSave"
        prepend-icon="mdi-content-save"
      >
        Save
      </v-btn>
    </div>

    <!-- Tabbed Sections -->
    <v-card>
      <v-tabs v-model="activeTab" grow>
        <v-tab value="basic">Basic Information</v-tab>
        <v-tab value="parties">
          Parties
          <v-badge
            v-if="presentationData.parties.length"
            :content="presentationData.parties.length"
            color="primary"
            class="ml-2"
          ></v-badge>
        </v-tab>
        <v-tab value="evidence">
          Evidence
          <v-badge
            v-if="presentationData.evidence?.length"
            :content="presentationData.evidence.length"
            color="primary"
            class="ml-2"
          ></v-badge>
        </v-tab>
        <v-tab value="events">
          Events
          <v-badge
            v-if="presentationData.events?.length"
            :content="presentationData.events.length"
            color="primary"
            class="ml-2"
          ></v-badge>
        </v-tab>
      </v-tabs>

      <v-card-text>
        <v-window v-model="activeTab">
          <!-- Basic Information Tab -->
          <v-window-item value="basic">
            <v-form ref="basicForm">
              <v-row>
                <!-- Featured Image Section - Left 1/3 -->
                <v-col cols="12" md="4">
                  <div class="featured-image-container">
                    <h3 class="text-h6 mb-4">Featured Image</h3>
                    
                    <!-- Image Preview -->
                    <v-card
                      class="mb-4"
                      variant="outlined"
                      min-height="200"
                    >
                      <v-img
                        v-if="presentationData.featuredImage"
                        :src="presentationData.featuredImage"
                        cover
                        height="300"
                        class="featured-preview"
                      >
                        <template v-slot:placeholder>
                          <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </div>
                        </template>
                      </v-img>
                      <div
                        v-else
                        class="d-flex align-center justify-center fill-height grey-lighten-3"
                        style="height: 300px;"
                      >
                        <v-icon
                          size="64"
                          color="grey-darken-2"
                        >
                          mdi-image-outline
                        </v-icon>
                      </div>
                    </v-card>

                    <!-- Upload Controls -->
                    <div class="d-flex flex-column gap-2">
                      <v-file-input
                        v-model="featuredImageFile"
                        accept="image/*"
                        label="Upload Featured Image"
                        prepend-icon="mdi-camera"
                        :loading="uploadingFeaturedImage"
                        :error-messages="featuredImageError"
                        hide-details
                        @update:model-value="handleFeaturedImageUpload"
                      ></v-file-input>
                      
                      <v-btn
                        v-if="presentationData.featuredImage"
                        color="error"
                        variant="text"
                        @click="removeFeaturedImage"
                        :disabled="uploadingFeaturedImage"
                        class="mt-2"
                      >
                        Remove Image
                      </v-btn>
                    </div>
                  </div>
                </v-col>

                <!-- Form Fields - Right 2/3 -->
                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="presentationData.title"
                        label="Case Title"
                        required
                        :rules="[v => !!v || 'Title is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="presentationData.caseNumber"
                        label="Case Number"
                        required
                        :rules="[v => !!v || 'Case number is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="presentationData.judge"
                        label="Judge"
                        required
                        :rules="[v => !!v || 'Judge name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="presentationData.venue"
                        label="Venue"
                        required
                        :rules="[v => !!v || 'Venue is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="presentationData.trialDate"
                        label="Trial Date"
                        type="date"
                        required
                        :rules="[v => !!v || 'Trial date is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="presentationData.status"
                        :items="statusOptions"
                        label="Status"
                        required
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip
                            :color="getStatusColor(item.value)"
                            size="small"
                            class="mr-2"
                          >
                            {{ item.title }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item, props }">
                          <v-list-item
                            v-bind="props"
                            :prepend-icon="getStatusIcon(item.value)"
                            :subtitle="getStatusDescription(item.value)"
                          >
                            <template v-slot:prepend>
                              <v-icon :color="getStatusColor(item.value)">
                                {{ getStatusIcon(item.value) }}
                              </v-icon>
                            </template>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="presentationData.description"
                        label="Description"
                        rows="4"
                        counter
                        maxlength="500"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-window-item>

          <!-- Parties Tab -->
          <v-window-item value="parties">
            <div class="d-flex justify-end mb-4">
              <v-btn
                variant="text"
                color="primary"
                prepend-icon="mdi-dice-multiple"
                class="mr-2"
                @click="generateRandomParty"
                :loading="generating"
              >
                Generate Random Party
              </v-btn>
              <v-btn
                color="primary"
                prepend-icon="mdi-plus"
                @click="addParty"
              >
                Add Party
              </v-btn>
            </div>

            <v-row>
              <v-col v-for="(party, index) in presentationData.parties" :key="index" cols="12">
                <v-card variant="outlined" class="mb-4">
                  <v-card-text>
                    <v-row>
                      <!-- Party Photo Section -->
                      <v-col cols="12" class="mb-4">
                        <div class="d-flex align-center justify-center">
                          <v-avatar size="120" color="grey-lighten-2" class="mb-2">
                            <v-img
                              v-if="party.photo"
                              :src="party.photo"
                              cover
                            >
                              <template v-slot:placeholder>
                                <v-progress-circular indeterminate></v-progress-circular>
                              </template>
                            </v-img>
                            <v-icon
                              v-else
                              size="48"
                              color="grey-darken-2"
                            >
                              mdi-account
                            </v-icon>
                          </v-avatar>
                        </div>
                        <div class="d-flex justify-center gap-2">
                          <v-btn
                            size="small"
                            variant="text"
                            prepend-icon="mdi-camera"
                            @click="() => handlePhotoClick(index)"
                          >
                            {{ party.photo ? 'Change Photo' : 'Add Photo' }}
                          </v-btn>
                          <v-btn
                            v-if="party.photo"
                            size="small"
                            variant="text"
                            color="error"
                            prepend-icon="mdi-delete"
                            @click="() => removePartyPhoto(index)"
                          >
                            Remove
                          </v-btn>
                        </div>
                        <input
                          type="file"
                          :ref="el => partyPhotoInputs[index] = el"
                          class="d-none"
                          accept="image/*"
                          @change="e => handlePartyPhotoSelect(e, index)"
                        >
                      </v-col>

                      <!-- Party Details -->
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="party.name"
                          label="Name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="party.role"
                          :items="partyRoles"
                          label="Role"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="party.email"
                          label="Email"
                          type="email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="party.phone"
                          label="Phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <div class="d-flex align-center mb-2">
                          <div class="text-subtitle-1">Links</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            size="small"
                            color="primary"
                            variant="text"
                            prepend-icon="mdi-plus"
                            @click="addPartyLink(index)"
                          >
                            Add Link
                          </v-btn>
                        </div>
                        
                        <div v-for="(link, linkIndex) in party.links" :key="linkIndex" class="mb-3">
                          <div class="d-flex gap-2">
                            <v-text-field
                              v-model="link.label"
                              label="Label"
                              placeholder="e.g., LinkedIn Profile"
                              density="comfortable"
                              class="flex-grow-0"
                              style="width: 200px;"
                            ></v-text-field>
                            <v-text-field
                              v-model="link.url"
                              label="URL"
                              placeholder="https://"
                              density="comfortable"
                              class="flex-grow-1"
                            ></v-text-field>
                            <v-btn
                              icon="mdi-delete"
                              variant="text"
                              color="error"
                              size="small"
                              @click="removePartyLink(index, linkIndex)"
                            ></v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div class="d-flex justify-end">
                      <v-btn
                        icon="mdi-delete"
                        variant="text"
                        color="error"
                        @click="removeParty(index)"
                      ></v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>

          <!-- Evidence Tab -->
          <v-window-item value="evidence">
            <div class="d-flex justify-end mb-4">
              <v-btn
                variant="text"
                color="primary"
                prepend-icon="mdi-dice-multiple"
                class="mr-2"
                @click="generateRandomEvidence"
                :loading="generating"
              >
                Generate Random Evidence
              </v-btn>
              <v-btn
                color="primary"
                prepend-icon="mdi-plus"
                @click="addEvidence"
              >
                Add Evidence
              </v-btn>
            </div>

            <v-row>
              <v-col v-for="(evidence, index) in presentationData.evidence" :key="index" cols="12">
                <v-card :id="`evidence-${index}`" variant="outlined" class="mb-4">
                  <v-card-text>
                    <v-row>
                      <!-- Left Side - Inputs (2/3) -->
                      <v-col cols="12" md="8">
                        <v-row>
                          <!-- Title -->
                          <v-col cols="12">
                            <v-text-field
                              v-model="evidence.title"
                              label="Title"
                              required
                              :rules="[v => !!v || 'Title is required']"
                            ></v-text-field>
                          </v-col>

                          <!-- Description -->
                          <v-col cols="12">
                            <v-textarea
                              v-model="evidence.description"
                              label="Description"
                              rows="3"
                              counter
                              maxlength="500"
                            ></v-textarea>
                          </v-col>

                          <!-- Upload Controls -->
                          <v-col cols="12">
                            <v-file-input
                              v-model="evidence.photoFile"
                              accept="image/*"
                              label="Photo Upload"
                              prepend-icon="mdi-camera"
                              @update:model-value="(file) => handlePhotoUpload(file, index)"
                              :show-size="true"
                              :loading="evidence.photoUploading"
                              :error-messages="evidence.photoError"
                              hint="Upload a photo (max 10MB)"
                              persistent-hint
                              class="mb-4"
                            ></v-file-input>

                            <v-file-input
                              v-model="evidence.documentFile"
                              accept=".pdf,.doc,.docx"
                              label="Document Upload"
                              prepend-icon="mdi-file-document"
                              @update:model-value="(file) => handleDocumentUpload(file, index)"
                              :show-size="true"
                              :loading="evidence.documentUploading"
                              :error-messages="evidence.documentError"
                              hint="Upload a document (PDF, DOC, DOCX - max 10MB)"
                              persistent-hint
                            ></v-file-input>
                          </v-col>

                          <!-- External Link -->
                          <v-col cols="12">
                            <v-text-field
                              v-model="evidence.link"
                              label="External Link"
                              placeholder="https://"
                              clearable
                              :rules="[
                                v => !v || /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(v) || 'Please enter a valid URL'
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <!-- Right Side - Preview (1/3) -->
                      <v-col cols="12" md="4" class="preview-section">
                        <div class="preview-container">
                          <h3 class="text-subtitle-1 mb-3">Preview</h3>
                          
                          <!-- Photo Preview -->
                          <div v-if="evidence.photoUrl" class="preview-item mb-4">
                            <v-img
                              :src="evidence.photoUrl"
                              max-height="200"
                              contain
                              class="rounded mb-2 bg-grey-lighten-3"
                            ></v-img>
                            <v-btn
                              size="small"
                              color="error"
                              variant="text"
                              @click="removePhoto(index)"
                              block
                            >
                              Remove Photo
                            </v-btn>
                          </div>

                          <!-- Document Preview -->
                          <div v-if="evidence.documentUrl" class="preview-item mb-4">
                            <v-card variant="outlined" class="pa-3">
                              <div class="d-flex align-center mb-2">
                                <v-icon color="primary" class="mr-2">mdi-file-document-outline</v-icon>
                                <span class="text-truncate">{{ evidence.documentName }}</span>
                              </div>
                              <div class="d-flex gap-2">
                                <v-btn
                                  size="small"
                                  color="primary"
                                  variant="text"
                                  :href="evidence.documentUrl"
                                  target="_blank"
                                  prepend-icon="mdi-open-in-new"
                                >
                                  View
                                </v-btn>
                                <v-btn
                                  size="small"
                                  color="error"
                                  variant="text"
                                  @click="removeDocument(index)"
                                >
                                  Remove
                                </v-btn>
                              </div>
                            </v-card>
                          </div>

                          <!-- Link Preview -->
                          <div v-if="evidence.link" class="preview-item">
                            <v-card variant="outlined" class="pa-3">
                              <div class="d-flex align-center mb-2">
                                <v-icon color="primary" class="mr-2">mdi-link</v-icon>
                                <span class="text-truncate">{{ evidence.link }}</span>
                              </div>
                              <div class="d-flex gap-2">
                                <v-btn
                                  size="small"
                                  color="primary"
                                  variant="text"
                                  :href="formatLink(evidence.link)"
                                  target="_blank"
                                  prepend-icon="mdi-open-in-new"
                                >
                                  Visit
                                </v-btn>
                                <v-btn
                                  size="small"
                                  color="error"
                                  variant="text"
                                  @click="evidence.link = ''"
                                >
                                  Remove
                                </v-btn>
                              </div>
                            </v-card>
                          </div>

                          <!-- Empty State -->
                          <div v-if="!evidence.photoUrl && !evidence.documentUrl && !evidence.link" class="empty-preview">
                            <v-icon size="40" color="grey-lighten-1">mdi-image-outline</v-icon>
                            <span class="text-caption text-medium-emphasis">No files or links added</span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="text"
                      @click="removeEvidence(index)"
                    >
                      Remove Evidence
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>

          <!-- Events Tab -->
          <v-window-item value="events">
            <div class="d-flex justify-end mb-4">
              <v-btn
                variant="text"
                color="primary"
                prepend-icon="mdi-dice-multiple"
                class="mr-2"
                @click="generateRandomEvent"
                :loading="generating"
              >
                Generate Random Event
              </v-btn>
              <v-btn
                color="primary"
                prepend-icon="mdi-plus"
                @click="addEvent"
              >
                Add Event
              </v-btn>
            </div>

            <v-row>
              <v-col v-for="(event, index) in presentationData.events" :key="index" cols="12">
                <v-card variant="outlined" class="mb-4">
                  <v-card-text>
                    <v-row>
                      <!-- Title -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="event.title"
                          label="Event Title"
                          required
                          :rules="[v => !!v || 'Title is required']"
                        ></v-text-field>
                      </v-col>

                      <!-- Date and Time -->
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="event.date"
                          label="Date"
                          type="date"
                          required
                          :rules="[v => !!v || 'Date is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="event.time"
                          label="Time"
                          type="time"
                          required
                          :rules="[v => !!v || 'Time is required']"
                        ></v-text-field>
                      </v-col>

                      <!-- Location -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="event.location"
                          label="Location"
                          placeholder="Enter event location"
                          required
                          :rules="[v => !!v || 'Location is required']"
                        ></v-text-field>
                      </v-col>

                      <!-- Description -->
                      <v-col cols="12">
                        <v-textarea
                          v-model="event.description"
                          label="Description"
                          rows="3"
                          counter
                          maxlength="500"
                        ></v-textarea>
                      </v-col>

                      <!-- Associated Parties -->
                      <v-col cols="12">
                        <div class="text-subtitle-1 mb-2">Associated Parties</div>
                        <v-select
                          v-model="event.associatedParties"
                          :items="presentationData.parties"
                          item-title="name"
                          item-value="name"
                          label="Select Parties"
                          multiple
                          chips
                          closable-chips
                        >
                          <template v-slot:chip="{ props, item }">
                            <v-chip
                              v-bind="props"
                              :prepend-icon="getPartyIcon(item.raw)"
                            >
                              {{ item.title }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>

                      <!-- Associated Evidence -->
                      <v-col cols="12">
                        <div class="text-subtitle-1 mb-2">Associated Evidence</div>
                        <v-select
                          v-model="event.associatedEvidence"
                          :items="presentationData.evidence"
                          item-title="title"
                          item-value="title"
                          label="Select Evidence"
                          multiple
                          chips
                          closable-chips
                        >
                          <template v-slot:chip="{ props, item }">
                            <v-chip
                              v-bind="props"
                              prepend-icon="mdi-file-document-outline"
                            >
                              {{ item.title }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>

                      <!-- Preview Section -->
                      <v-col v-if="event.associatedParties?.length || event.associatedEvidence?.length" cols="12">
                        <v-card variant="outlined" class="pa-4">
                          <div v-if="event.associatedParties?.length" class="mb-4">
                            <div class="text-subtitle-2 mb-2">Associated Parties:</div>
                            <div class="d-flex flex-wrap gap-2">
                              <v-chip
                                v-for="partyName in event.associatedParties"
                                :key="partyName"
                                color="primary"
                                variant="outlined"
                              >
                                {{ partyName }}
                              </v-chip>
                            </div>
                          </div>
                          <div v-if="event.associatedEvidence?.length">
                            <div class="text-subtitle-2 mb-2">Associated Evidence:</div>
                            <div class="d-flex flex-wrap gap-2">
                              <v-chip
                                v-for="evidenceTitle in event.associatedEvidence"
                                :key="evidenceTitle"
                                color="primary"
                                variant="outlined"
                              >
                                {{ evidenceTitle }}
                              </v-chip>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="text"
                      @click="removeEvent(index)"
                    >
                      Remove Event
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <!-- Notifications -->
    <v-snackbar
      v-model="showMessage"
      :color="messageType"
      location="top"
    >
      {{ message }}
      <template v-slot:actions>
        <v-btn
          variant="text"
          @click="showMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuth } from '../stores/auth'
import { 
  db, 
  storage, 
  storageRef, 
  uploadBytes, 
  getDownloadURL 
} from '../firebase/firebase-config'
import { collection, addDoc } from 'firebase/firestore'
import { useDebugStore } from '../stores/debug'
import { useUserTierStore } from '../stores/userTier'

const router = useRouter()
const authStore = useAuth()
const debugStore = useDebugStore()
const userTierStore = useUserTierStore()

// Form refs
const basicForm = ref(null)
const saving = ref(false)
const showMessage = ref(false)
const message = ref('')
const messageType = ref('success')
const activeTab = ref('basic')
const partyPhotoInputs = ref({})
const featuredImageFile = ref(null)
const uploadingFeaturedImage = ref(false)
const featuredImageError = ref(null)

// Constants
const partyRoles = [
  'Plaintiff',
  'Defendant',
  'Witness',
  'Expert',
  'Attorney',
  'Judge',
  'Other'
]
const statusOptions = [
  { title: 'Draft', value: 'draft' },
  { title: 'Live', value: 'live' },
  { title: 'Archived', value: 'archived' }
]

// Initialize presentation data
const presentationData = ref({
  title: '',
  caseNumber: '',
  judge: '',
  venue: '',
  description: '',
  parties: [],
  evidence: [],
  events: [],
  status: 'draft',
  userId: null
})

// Methods
const showNotification = (msg, type = 'success') => {
  message.value = msg
  messageType.value = type
  showMessage.value = true
}

const handleSave = async () => {
  const userId = authStore?.user?.value?.uid
  if (!userId) {
    showNotification('Please log in to save', 'error')
    return
  }

  if (!userTierStore.canCreatePresentation()) {
    showNotification('You have reached your presentation limit. Please upgrade to create more presentations.', 'error')
    router.push('/pricing')
    return
  }
  
  try {
    saving.value = true
    
    const cleanData = {
      ...presentationData.value,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      userId,
      // Clean evidence array
      evidence: presentationData.value.evidence?.map(evidence => ({
        title: evidence.title,
        description: evidence.description,
        link: evidence.link,
        photoUrl: evidence.photoUrl,
        documentUrl: evidence.documentUrl,
        documentName: evidence.documentName
      })) || [],
      // Clean parties array
      parties: presentationData.value.parties?.map(party => ({
        name: party.name,
        role: party.role,
        email: party.email,
        phone: party.phone,
        photo: party.photo
      })) || [],
      // Clean events array
      events: presentationData.value.events?.map(event => ({
        title: event.title,
        date: event.date,
        time: event.time,
        location: event.location,
        description: event.description,
        associatedParties: event.associatedParties || [],
        associatedEvidence: event.associatedEvidence || []
      })) || []
    }
    
    const docRef = await addDoc(collection(db, 'presentations'), cleanData)
    showNotification('Presentation created successfully')
    router.push(`/presentation/${docRef.id}`)
  } catch (error) {
    console.error('Error creating presentation:', error)
    showNotification('Failed to create presentation', 'error')
  } finally {
    saving.value = false
  }
}

// Party management
const addParty = () => {
  presentationData.value.parties.push({
    name: '',
    role: '',
    email: '',
    phone: '',
    photo: null,
    links: []
  })
}

const removeParty = (index) => {
  presentationData.value.parties.splice(index, 1)
}

const handlePhotoClick = (index) => {
  if (partyPhotoInputs.value[index]) {
    partyPhotoInputs.value[index].click()
  }
}

const handlePartyPhotoSelect = async (event, index) => {
  const file = event?.target?.files?.[0]
  if (!file) return
  
  try {
    const safeFileName = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase()
    const path = `presentations/${authStore.user.value.uid}/parties/${Date.now()}_${safeFileName}`
    const fileRef = storageRef(storage, path)
    
    const metadata = {
      contentType: file.type,
      customMetadata: {
        'uploadedBy': authStore.user.value.uid,
        'originalName': file.name
      }
    }
    
    const uploadResult = await uploadBytes(fileRef, file, metadata)
    const url = await getDownloadURL(uploadResult.ref)
    
    presentationData.value.parties[index].photo = url
    showNotification('Photo uploaded successfully')
  } catch (error) {
    console.error('Error uploading photo:', error)
    showNotification('Failed to upload photo', 'error')
  }
}

const removePartyPhoto = (index) => {
  presentationData.value.parties[index].photo = null
  showNotification('Photo removed')
}

// Evidence management
const addEvidence = () => {
  presentationData.value.evidence.push({
    title: '',
    description: '',
    link: '',
    photoFile: null,
    photoUrl: null,
    photoUploading: false,
    photoError: null,
    documentFile: null,
    documentUrl: null,
    documentName: null,
    documentUploading: false,
    documentError: null
  })
}

const removeEvidence = (index) => {
  presentationData.value.evidence.splice(index, 1)
}

const handlePhotoUpload = async (file, index) => {
  if (!file) return

  try {
    presentationData.value.evidence[index].photoUploading = true
    presentationData.value.evidence[index].photoError = null

    const safeFileName = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase()
    const path = `presentations/${authStore.user.value.uid}/evidence/${Date.now()}_${safeFileName}`
    const fileRef = storageRef(storage, path)
    
    const metadata = {
      contentType: file.type,
      customMetadata: {
        'uploadedBy': authStore.user.value.uid,
        'originalName': file.name
      }
    }
    
    const uploadResult = await uploadBytes(fileRef, file, metadata)
    const url = await getDownloadURL(uploadResult.ref)
    
    presentationData.value.evidence[index].photoUrl = url
    presentationData.value.evidence[index].photoFile = null

    showNotification('Photo uploaded successfully')
  } catch (error) {
    console.error('Error uploading photo:', error)
    presentationData.value.evidence[index].photoError = error.message
    showNotification('Failed to upload photo', 'error')
  } finally {
    presentationData.value.evidence[index].photoUploading = false
  }
}

const handleDocumentUpload = async (file, index) => {
  if (!file) return

  try {
    presentationData.value.evidence[index].documentUploading = true
    presentationData.value.evidence[index].documentError = null

    const safeFileName = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase()
    const path = `presentations/${authStore.user.value.uid}/evidence/${Date.now()}_${safeFileName}`
    const fileRef = storageRef(storage, path)
    
    const metadata = {
      contentType: file.type,
      customMetadata: {
        'uploadedBy': authStore.user.value.uid,
        'originalName': file.name
      }
    }
    
    const uploadResult = await uploadBytes(fileRef, file, metadata)
    const url = await getDownloadURL(uploadResult.ref)
    
    presentationData.value.evidence[index].documentUrl = url
    presentationData.value.evidence[index].documentName = file.name
    presentationData.value.evidence[index].documentFile = null

    showNotification('Document uploaded successfully')
  } catch (error) {
    console.error('Error uploading document:', error)
    presentationData.value.evidence[index].documentError = error.message
    showNotification('Failed to upload document', 'error')
  } finally {
    presentationData.value.evidence[index].documentUploading = false
  }
}

const removePhoto = (index) => {
  presentationData.value.evidence[index].photoUrl = null
  presentationData.value.evidence[index].photoFile = null
  showNotification('Photo removed')
}

const removeDocument = (index) => {
  presentationData.value.evidence[index].documentUrl = null
  presentationData.value.evidence[index].documentName = null
  presentationData.value.evidence[index].documentFile = null
  showNotification('Document removed')
}

// Event management
const addEvent = () => {
  presentationData.value.events.push({
    title: '',
    date: '',
    time: '',
    location: '',
    description: '',
    associatedParties: [],
    associatedEvidence: []
  })
}

const removeEvent = (index) => {
  presentationData.value.events.splice(index, 1)
  showNotification('Event removed')
}

const getPartyIcon = (party) => {
  const iconMap = {
    'Plaintiff': 'mdi-account',
    'Defendant': 'mdi-account-alert',
    'Witness': 'mdi-account-voice',
    'Expert': 'mdi-account-tie',
    'Attorney': 'mdi-account-tie-voice',
    'Judge': 'mdi-gavel',
    'Other': 'mdi-account'
  }
  return iconMap[party?.role] || 'mdi-account'
}

const formatLink = (link) => {
  if (!link) return ''
  return link.startsWith('http') ? link : `https://${link}`
}

// Add these helper methods
const getStatusColor = (status) => {
  const colorMap = {
    draft: 'grey',
    live: 'success',
    archived: 'error'
  }
  return colorMap[status] || 'grey'
}

const getStatusIcon = (status) => {
  const iconMap = {
    draft: 'mdi-pencil-outline',
    live: 'mdi-check-circle',
    archived: 'mdi-archive'
  }
  return iconMap[status] || 'mdi-help-circle'
}

const getStatusDescription = (status) => {
  const descriptionMap = {
    draft: 'Work in progress, not ready for presentation',
    live: 'Ready for presentation and actively in use',
    archived: 'No longer active, preserved for reference'
  }
  return descriptionMap[status] || ''
}

// Add featured image methods
const handleFeaturedImageUpload = async (file) => {
  if (!file) return
  
  try {
    uploadingFeaturedImage.value = true
    featuredImageError.value = null
    
    const safeFileName = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase()
    const path = `presentations/${authStore.user.value.uid}/featured/${Date.now()}_${safeFileName}`
    const fileRef = storageRef(storage, path)
    
    const metadata = {
      contentType: file.type,
      customMetadata: {
        'uploadedBy': authStore.user.value.uid,
        'originalName': file.name
      }
    }
    
    const uploadResult = await uploadBytes(fileRef, file, metadata)
    const url = await getDownloadURL(uploadResult.ref)
    
    presentationData.value.featuredImage = url
    featuredImageFile.value = null
    
    showNotification('Featured image uploaded successfully')
  } catch (error) {
    console.error('Error uploading featured image:', error)
    featuredImageError.value = error.message
    showNotification('Failed to upload featured image', 'error')
  } finally {
    uploadingFeaturedImage.value = false
  }
}

const removeFeaturedImage = () => {
  presentationData.value.featuredImage = null
  featuredImageFile.value = null
  showNotification('Featured image removed')
}

// Initialize
onMounted(async () => {
  const userId = authStore?.user?.value?.uid
  if (!userId) {
    router.push('/login')
    return
  }
  
  await userTierStore.loadUserTier(userId)
  if (!userTierStore.canCreatePresentation()) {
    showNotification('You have reached your presentation limit. Please upgrade to create more presentations.', 'error')
    router.push('/pricing')
    return
  }
  
  presentationData.value.userId = userId
})

// Add to your script section
const addPartyLink = (partyIndex) => {
  if (!presentationData.value.parties[partyIndex].links) {
    presentationData.value.parties[partyIndex].links = []
  }
  presentationData.value.parties[partyIndex].links.push({
    label: '',
    url: ''
  })
}

const removePartyLink = (partyIndex, linkIndex) => {
  presentationData.value.parties[partyIndex].links.splice(linkIndex, 1)
}

// Add to script setup
const generating = ref(false)

// Random data generators
const generateRandomParty = () => {
  const firstNames = ['John', 'Jane', 'Robert', 'Mary', 'William', 'Elizabeth', 'Michael', 'Sarah']
  const lastNames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Davis', 'Miller', 'Wilson', 'Moore']
  const roles = ['Plaintiff', 'Defendant', 'Witness', 'Expert', 'Attorney']
  
  const randomParty = {
    name: `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${lastNames[Math.floor(Math.random() * lastNames.length)]}`,
    role: roles[Math.floor(Math.random() * roles.length)],
    email: `example${Math.floor(Math.random() * 1000)}@email.com`,
    phone: `(555) ${String(Math.floor(Math.random() * 900) + 100)}-${String(Math.floor(Math.random() * 9000) + 1000)}`,
    photo: null,
    links: [
      {
        label: 'LinkedIn Profile',
        url: `https://linkedin.com/in/demo-${Math.random().toString(36).substring(7)}`
      }
    ]
  }
  
  presentationData.value.parties.push(randomParty)
  showNotification('Random party generated')
}

const generateRandomEvidence = () => {
  const types = ['Financial Statement', 'Email Chain', 'Contract', 'Report', 'Photograph']
  const type = types[Math.floor(Math.random() * types.length)]
  
  const randomEvidence = {
    title: `${type} - ${Math.random().toString(36).substring(7).toUpperCase()}`,
    description: `Legal Analysis: This ${type.toLowerCase()} provides crucial evidence regarding the case. Key observations include significant patterns and relevant details that support our position.`,
    link: `https://example.com/evidence-${Math.random().toString(36).substring(7)}`,
    photoUrl: null,
    documentUrl: null,
    documentName: `evidence-${Math.random().toString(36).substring(7)}.pdf`
  }
  
  presentationData.value.evidence.push(randomEvidence)
  showNotification('Random evidence generated')
}

const generateRandomEvent = () => {
  const types = ['Deposition', 'Hearing', 'Meeting', 'Document Review', 'Interview']
  const locations = ['Room', 'Office', 'Courthouse', 'Conference Center']
  const type = types[Math.floor(Math.random() * types.length)]
  
  // Generate a random date within the next 30 days
  const randomDate = new Date(Date.now() + Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000)
  
  const randomEvent = {
    title: `${type} - ${Math.random().toString(36).substring(7).toUpperCase()}`,
    date: randomDate.toISOString().split('T')[0],
    time: `${String(9 + Math.floor(Math.random() * 8)).padStart(2, '0')}:00`,
    location: `${locations[Math.floor(Math.random() * locations.length)]} ${100 + Math.floor(Math.random() * 900)}`,
    description: `This ${type.toLowerCase()} is scheduled to address key aspects of the case. Important matters will be discussed and documented.`,
    associatedParties: [],
    associatedEvidence: []
  }
  
  presentationData.value.events.push(randomEvent)
  showNotification('Random event generated')
}
</script>

<style scoped>
.featured-image-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.featured-preview {
  border-radius: 4px;
  overflow: hidden;
}

.gap-2 {
  gap: 0.5rem;
}

.v-card-text {
  padding-top: 24px;
}
</style> 