<template>
  <div class="documentation">
    <v-container class="py-8">
      <!-- Header -->
      <div class="mb-8">
        <h1 class="text-h3 font-weight-light mb-2">Documentation</h1>
        <p class="text-h6 text-medium-emphasis">
          Learn how to use LegalLogic effectively for your legal presentations
        </p>
      </div>

      <v-row>
        <!-- Navigation Sidebar -->
        <v-col cols="12" md="3">
          <v-card class="sticky-sidebar" flat>
            <v-list density="compact">
              <v-list-item
                v-for="section in sections"
                :key="section.id"
                :value="section"
                :active="currentSection === section.id"
                @click="currentSection = section.id"
                class="rounded-lg"
              >
                <template v-slot:prepend>
                  <v-icon :icon="section.icon" size="small"></v-icon>
                </template>
                <v-list-item-title>{{ section.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <!-- Content Area -->
        <v-col cols="12" md="9">
          <v-card flat>
            <v-window v-model="currentSection">
              <!-- Getting Started -->
              <v-window-item value="getting-started">
                <div class="pa-4">
                  <h2 class="text-h4 mb-6">Getting Started</h2>
                  
                  <div class="mb-8">
                    <h3 class="text-h5 mb-4">Creating Your First Presentation</h3>
                    <v-card variant="outlined" class="mb-4">
                      <v-card-text>
                        <ol class="steps-list">
                          <li>Navigate to the Library section</li>
                          <li>Click the "New Presentation" button</li>
                          <li>Fill in the basic information</li>
                          <li>Add parties involved in your case</li>
                          <li>Upload and organize your evidence</li>
                          <li>Create a timeline of events</li>
                        </ol>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="mb-8">
                    <h3 class="text-h5 mb-4">Key Features Overview</h3>
                    <v-row>
                      <v-col v-for="feature in keyFeatures" :key="feature.title" cols="12" md="6">
                        <v-card variant="outlined" class="h-100">
                          <v-card-text>
                            <div class="d-flex align-center mb-4">
                              <v-avatar
                                :color="feature.color"
                                size="36"
                                class="mr-4"
                              >
                                <v-icon :icon="feature.icon" color="white"></v-icon>
                              </v-avatar>
                              <h4 class="text-h6">{{ feature.title }}</h4>
                            </div>
                            <p>{{ feature.description }}</p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-window-item>

              <!-- Managing Evidence -->
              <v-window-item value="managing-evidence">
                <div class="pa-4">
                  <h2 class="text-h4 mb-6">Managing Evidence</h2>
                  
                  <div class="mb-8">
                    <h3 class="text-h5 mb-4">Uploading Evidence</h3>
                    <v-alert
                      color="info"
                      variant="outlined"
                      class="mb-4"
                    >
                      Supported file types: Images (JPG, PNG), Documents (PDF, DOC, DOCX)
                    </v-alert>
                    <v-card variant="outlined" class="mb-4">
                      <v-card-text>
                        <h4 class="text-h6 mb-2">Best Practices</h4>
                        <ul class="mb-4">
                          <li>Use clear, descriptive titles for your evidence</li>
                          <li>Add detailed descriptions for easy reference</li>
                          <li>Organize evidence by type or relevance</li>
                          <li>Link evidence to specific events in your timeline</li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-window-item>

              <!-- Presentation Mode -->
              <v-window-item value="presentation-mode">
                <div class="pa-4">
                  <h2 class="text-h4 mb-6">Presentation Mode</h2>
                  
                  <div class="mb-8">
                    <h3 class="text-h5 mb-4">Using Presentation Mode</h3>
                    <v-card variant="outlined" class="mb-4">
                      <v-card-text>
                        <h4 class="text-h6 mb-2">Features</h4>
                        <ul>
                          <li>Full-screen presentation view</li>
                          <li>Easy navigation between events</li>
                          <li>Quick access to linked evidence</li>
                          <li>Real-time collaboration options</li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-window-item>

              <!-- FAQ -->
              <v-window-item value="faq">
                <div class="pa-4">
                  <h2 class="text-h4 mb-6">Frequently Asked Questions</h2>
                  
                  <v-expansion-panels variant="accordion">
                    <v-expansion-panel
                      v-for="(faq, index) in faqs"
                      :key="index"
                    >
                      <v-expansion-panel-title>
                        {{ faq.question }}
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        {{ faq.answer }}
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const currentSection = ref('getting-started')

const sections = [
  {
    id: 'getting-started',
    title: 'Getting Started',
    icon: 'mdi-book-open-page-variant'
  },
  {
    id: 'managing-evidence',
    title: 'Managing Evidence',
    icon: 'mdi-file-document-multiple'
  },
  {
    id: 'presentation-mode',
    title: 'Presentation Mode',
    icon: 'mdi-presentation'
  },
  {
    id: 'faq',
    title: 'FAQ',
    icon: 'mdi-frequently-asked-questions'
  }
]

const keyFeatures = [
  {
    title: 'Evidence Management',
    description: 'Upload, organize, and present your evidence efficiently.',
    icon: 'mdi-file-document-multiple',
    color: 'primary'
  },
  {
    title: 'Timeline Creation',
    description: 'Build comprehensive case timelines with linked evidence.',
    icon: 'mdi-timeline',
    color: 'secondary'
  },
  {
    title: 'Party Management',
    description: 'Keep track of all parties involved in your case.',
    icon: 'mdi-account-group',
    color: 'success'
  },
  {
    title: 'Presentation Mode',
    description: 'Present your case with confidence using our presentation tools.',
    icon: 'mdi-presentation',
    color: 'info'
  }
]

const faqs = [
  {
    question: 'What file types are supported for evidence uploads?',
    answer: 'We support most common file types including PDFs, Word documents (DOC, DOCX), images (JPG, PNG), and more. Each file must be under 10MB in size.'
  },
  {
    question: 'Can I share my presentations with other team members?',
    answer: 'Yes, you can share presentations with team members who have LegalLogic accounts. Premium users have additional sharing and collaboration features.'
  },
  {
    question: 'How do I organize evidence for a large case?',
    answer: 'We recommend using descriptive titles, detailed descriptions, and our tagging system to organize evidence. You can also link evidence to specific events in your timeline.'
  },
  {
    question: 'Is my data secure?',
    answer: 'Yes, we use enterprise-grade encryption and security measures to protect your data. All files are stored securely and backed up regularly.'
  }
]
</script>

<style scoped>
.sticky-sidebar {
  position: sticky;
  top: 24px;
}

.steps-list {
  padding-left: 20px;
}

.steps-list li {
  margin-bottom: 12px;
}

:deep(.v-expansion-panel-title) {
  font-size: 1.1rem;
}
</style> 