<template>
  <div class="pricing-page fill-height">
    <div class="hero-section">
      <v-container class="py-16">
        <h1 class="text-h2 font-weight-light text-white text-center mb-4">
          Simple, Transparent Pricing
        </h1>
        <p class="text-h6 font-weight-light text-white text-center mb-16">
          Choose the plan that best fits your needs
        </p>
      </v-container>
    </div>
    
    <v-container class="pricing-container" style="margin-top: -64px;">
      <!-- Current Plan Alert -->
      <v-alert
        v-if="isAuthenticated && userTierStore.userTier"
        :color="getPlanColor(userTierStore.userTier)"
        variant="tonal"
        class="mb-6"
      >
        <div class="text-subtitle-1">
          Current Plan: {{ getPlanLabel(userTierStore.userTier) }}
        </div>
        <div class="text-body-2">
          Presentations: {{ userTierStore.presentationCount }} / 
          {{ getPlanLimit(userTierStore.userTier) }}
        </div>
      </v-alert>

      <!-- Plans Grid -->
      <v-row align="stretch">
        <!-- Free Tier -->
        <v-col cols="12" md="4">
          <v-card class="h-100" variant="outlined">
            <v-card-item>
              <div class="text-h5 mb-2">Free</div>
              <div class="text-h3 mb-2">$0<span class="text-body-1">/month</span></div>
              <div class="text-caption">Perfect for individual users</div>
            </v-card-item>

            <v-card-text>
              <v-list density="compact">
                <v-list-item
                  v-for="feature in freeTierFeatures"
                  :key="feature"
                  :prepend-icon="'mdi-check'"
                  :title="feature"
                ></v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions class="pa-4">
              <v-btn
                block
                color="primary"
                variant="outlined"
                :to="isAuthenticated ? undefined : '/login'"
                :disabled="isAuthenticated && userTierStore.userTier === 'free'"
                @click="isAuthenticated ? setTier('free') : null"
              >
                {{ getButtonText('free') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Pro Tier -->
        <v-col cols="12" md="4">
          <v-card class="h-100" variant="outlined" elevation="3">
            <v-card-item>
              <div class="d-flex align-center mb-2">
                <span class="text-h5">Pro</span>
                <v-chip class="ml-2" color="primary" size="small">Popular</v-chip>
              </div>
              <div class="text-h3 mb-2">$29<span class="text-body-1">/month</span></div>
              <div class="text-caption">For power users</div>
            </v-card-item>

            <v-card-text>
              <v-list density="compact">
                <v-list-item
                  v-for="feature in proTierFeatures"
                  :key="feature"
                  :prepend-icon="'mdi-check'"
                  :title="feature"
                ></v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions class="pa-4">
              <v-btn
                block
                color="primary"
                :to="isAuthenticated ? undefined : '/login'"
                :disabled="isAuthenticated && userTierStore.userTier === 'pro'"
                @click="isAuthenticated ? setTier('pro') : null"
              >
                {{ getButtonText('pro') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Enterprise Tier -->
        <v-col cols="12" md="4">
          <v-card class="h-100" variant="outlined">
            <v-card-item>
              <div class="text-h5 mb-2">Enterprise</div>
              <div class="text-h3 mb-2">$99<span class="text-body-1">/month</span></div>
              <div class="text-caption">20% off for 10+ seats</div>
            </v-card-item>

            <v-card-text>
              <v-list density="compact">
                <v-list-item
                  v-for="feature in enterpriseTierFeatures"
                  :key="feature"
                  :prepend-icon="'mdi-check'"
                  :title="feature"
                ></v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions class="pa-4">
              <v-btn
                block
                color="primary"
                variant="outlined"
                :to="isAuthenticated ? undefined : '/login'"
                @click="contactSales"
              >
                Contact Sales
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Toast Notifications -->
    <v-snackbar
      v-model="showToast"
      :color="toastType"
      location="top"
    >
      {{ toastMessage }}
      <template v-slot:actions>
        <v-btn
          variant="text"
          @click="showToast = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useUserTierStore } from '../stores/userTier'
import { useAuth } from '../stores/auth'
import { useRouter } from 'vue-router'

const router = useRouter()
const userTierStore = useUserTierStore()
const { isAuthenticated } = useAuth()

const showToast = ref(false)
const toastMessage = ref('')
const toastType = ref('success')

// Features for each tier
const freeTierFeatures = [
  '1 Presentation',
  'Basic Evidence Management',
  'Basic Timeline Creation',
  'Email Support'
]

const proTierFeatures = [
  'Unlimited Presentations',
  'Advanced Evidence Management',
  'Advanced Timeline Creation',
  'Priority Support',
  'Team Collaboration',
  'Custom Branding'
]

const enterpriseTierFeatures = [
  'Everything in Pro',
  'Unlimited Team Members',
  'Dedicated Account Manager',
  'Custom Integration',
  'Advanced Security',
  'Training Sessions'
]

// Helper functions
const getPlanColor = (tier) => {
  const colors = {
    free: 'info',
    pro: 'primary',
    enterprise: 'success'
  }
  return colors[tier] || 'grey'
}

const getPlanLabel = (tier) => {
  const labels = {
    free: 'Free',
    pro: 'Pro',
    enterprise: 'Enterprise'
  }
  return labels[tier] || 'Unknown'
}

const getPlanLimit = (tier) => {
  const limits = {
    free: '1',
    pro: '∞',
    enterprise: '∞'
  }
  return limits[tier] || '0'
}

const getButtonText = (tier) => {
  if (!isAuthenticated) return 'Sign In to Get Started'
  if (userTierStore.userTier === tier) return 'Current Plan'
  return tier === 'free' ? 'Downgrade to Free' : 'Upgrade to ' + getPlanLabel(tier)
}

const setTier = async (tier) => {
  try {
    if (tier === 'pro') {
      await userTierStore.setUserAsPro()
      showToast.value = true
      toastMessage.value = 'Successfully upgraded to Pro!'
      toastType.value = 'success'
    } else if (tier === 'free') {
      await userTierStore.resetToFreeTier()
      showToast.value = true
      toastMessage.value = 'Successfully downgraded to Free'
      toastType.value = 'info'
    }
  } catch (error) {
    showToast.value = true
    toastMessage.value = 'Failed to update plan'
    toastType.value = 'error'
  }
}

const contactSales = () => {
  if (!isAuthenticated.value) {
    router.push('/login')
    return
  }
  // Implement contact sales functionality
  window.location.href = 'mailto:sales@legallogic.io?subject=Enterprise Plan Inquiry'
}
</script>

<style scoped>
.pricing-page {
  background: rgb(var(--v-theme-background));
}

.hero-section {
  background-image: url('/images/homepage_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: relative;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-section > * {
  position: relative;
  z-index: 2;
}

.v-card {
  transition: transform 0.2s ease;
  background: rgb(var(--v-theme-background));
}

.v-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
}

.pricing-container {
  position: relative;
  z-index: 3;
}
</style> 