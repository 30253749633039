<template>
  <div class="features">
    <!-- Hero Section -->
    <div class="hero-section">
      <v-container class="py-16 content-container">
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" class="text-center">
            <h1 class="text-h2 font-weight-bold mb-4">
              Powerful Features for Modern Legal Workflows
            </h1>
            <p class="text-h6 font-weight-light text-white mb-8">
              From AI-powered analysis to real-time collaboration, LegalLogic empowers legal professionals with the tools they need to excel.
            </p>
            <v-btn
              size="x-large"
              color="primary"
              class="px-8"
              href="#features"
              @click.prevent="scrollToFeatures"
            >
              Explore All Features
              <v-icon end>mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Features Grid -->
    <div id="features">
      <!-- AI-Powered Analysis -->
      <section class="py-16">
        <v-container class="content-container">
          <v-row align="center" class="feature-row">
            <v-col cols="12" md="6" class="pr-md-12">
              <v-chip color="primary" class="mb-4">AI-Powered</v-chip>
              <h2 class="text-h3 font-weight-bold mb-4">
                Smarter Decisions with AI-Powered Insights
              </h2>
              <p class="text-h6 text-medium-emphasis mb-8">
                Advanced artificial intelligence analyzes legal documents, identifies key information, and streamlines your workflow.
              </p>
              <v-list class="bg-transparent pa-0">
                <v-list-item
                  v-for="(feature, index) in aiFeatures"
                  :key="index"
                  class="px-0"
                >
                  <template v-slot:prepend>
                    <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
                  </template>
                  <v-list-item-title>{{ feature }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div class="feature-animation d-flex justify-center">
                <lord-icon
                  src="https://cdn.lordicon.com/qhviklyi.json"
                  trigger="hover"
                  colors="primary:#121331,secondary:#3080e8"
                  style="width:400px;height:400px"
                >
                </lord-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Document Management -->
      <section class="py-16 bg-surface">
        <v-container class="content-container">
          <v-row align="center" class="feature-row flex-row-reverse">
            <v-col cols="12" md="6" class="pl-md-12">
              <v-chip color="secondary" class="mb-4">Organization</v-chip>
              <h2 class="text-h3 font-weight-bold mb-4">
                Effortless Document Organization
              </h2>
              <p class="text-h6 text-medium-emphasis mb-8">
                A comprehensive document management system designed specifically for legal professionals.
              </p>
              <v-list class="bg-transparent pa-0">
                <v-list-item
                  v-for="(feature, index) in documentFeatures"
                  :key="index"
                  class="px-0"
                >
                  <template v-slot:prepend>
                    <v-icon color="secondary" class="mr-4">mdi-check-circle</v-icon>
                  </template>
                  <v-list-item-title>{{ feature }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div class="feature-animation d-flex justify-center">
                <lord-icon
                  src="https://cdn.lordicon.com/nocovwne.json"
                  trigger="hover"
                  colors="primary:#121331,secondary:#3080e8"
                  style="width:400px;height:400px"
                >
                </lord-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Security -->
      <section class="py-16">
        <v-container class="content-container">
          <v-row align="center" class="feature-row">
            <v-col cols="12" md="6" class="pr-md-12">
              <v-chip color="success" class="mb-4">Security</v-chip>
              <h2 class="text-h3 font-weight-bold mb-4">
                Unparalleled Security for Your Legal Data
              </h2>
              <p class="text-h6 text-medium-emphasis mb-8">
                Enterprise-grade security features to protect your sensitive legal information.
              </p>
              <v-list class="bg-transparent pa-0">
                <v-list-item
                  v-for="(feature, index) in securityFeatures"
                  :key="index"
                  class="px-0"
                >
                  <template v-slot:prepend>
                    <v-icon color="success" class="mr-4">mdi-check-circle</v-icon>
                  </template>
                  <v-list-item-title>{{ feature }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div class="feature-animation d-flex justify-center">
                <lord-icon
                  src="https://cdn.lordicon.com/rqqkvjqf.json"
                  trigger="hover"
                  colors="primary:#121331,secondary:#3080e8"
                  style="width:400px;height:400px"
                >
                </lord-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Collaboration -->
      <section class="py-16 bg-surface">
        <v-container class="content-container">
          <v-row align="center" class="feature-row flex-row-reverse">
            <v-col cols="12" md="6" class="pl-md-12">
              <v-chip color="info" class="mb-4">Collaboration</v-chip>
              <h2 class="text-h3 font-weight-bold mb-4">
                Seamless Collaboration for Legal Teams
              </h2>
              <p class="text-h6 text-medium-emphasis mb-8">
                Real-time collaboration tools that enable teamwork and communication without compromise.
              </p>
              <v-list class="bg-transparent pa-0">
                <v-list-item
                  v-for="(feature, index) in collaborationFeatures"
                  :key="index"
                  class="px-0"
                >
                  <template v-slot:prepend>
                    <v-icon color="info" class="mr-4">mdi-check-circle</v-icon>
                  </template>
                  <v-list-item-title>{{ feature }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div class="feature-animation d-flex justify-center">
                <lord-icon
                  src="https://cdn.lordicon.com/uukerzzv.json"
                  trigger="hover"
                  colors="primary:#121331,secondary:#3080e8"
                  style="width:400px;height:400px"
                >
                </lord-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Case Management -->
      <section class="py-16">
        <v-container class="content-container">
          <v-row align="center" class="feature-row">
            <v-col cols="12" md="6" class="pr-md-12">
              <v-chip color="warning" class="mb-4">Case Management</v-chip>
              <h2 class="text-h3 font-weight-bold mb-4">
                Comprehensive Case Management
              </h2>
              <p class="text-h6 text-medium-emphasis mb-8">
                Organize and track your legal matters with tools designed for efficiency and clarity.
              </p>
              <v-list class="bg-transparent pa-0">
                <v-list-item
                  v-for="(feature, index) in caseFeatures"
                  :key="index"
                  class="px-0"
                >
                  <template v-slot:prepend>
                    <v-icon color="warning" class="mr-4">mdi-check-circle</v-icon>
                  </template>
                  <v-list-item-title>{{ feature }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div class="feature-animation d-flex justify-center">
                <lord-icon
                  src="https://cdn.lordicon.com/kbtmbyzy.json"
                  trigger="hover"
                  colors="primary:#121331,secondary:#3080e8"
                  style="width:400px;height:400px"
                >
                </lord-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>

    <!-- CTA Section -->
    <section class="py-16 cta-section">
      <v-container class="content-container">
        <v-row justify="center">
          <v-col cols="12" md="8" class="text-center">
            <h2 class="text-h3 font-weight-light text-white mb-6">
              Experience the Full Power of LegalLogic
            </h2>
            <p class="text-h6 text-white-darken-2 mb-12">
              Join thousands of legal professionals who trust LegalLogic
            </p>
            <div class="d-flex justify-center gap-4">
              <v-btn
                color="primary"
                size="x-large"
                class="px-8"
                :to="isAuthenticated ? '/library' : '/login'"
              >
                Get Started Free
              </v-btn>
              <v-btn
                variant="outlined"
                color="secondary"
                size="x-large"
                class="px-8"
                href="mailto:sales@legallogic.io"
              >
                Contact Sales
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script setup>
import { useAuth } from '../stores/auth'

const { isAuthenticated } = useAuth()

const aiFeatures = [
  'Smart document classification',
  'Key clause identification',
  'Risk assessment automation',
  'Precedent analysis'
]

const documentFeatures = [
  'Centralized document repository',
  'Advanced search capabilities',
  'Version control system',
  'Secure document sharing'
]

const securityFeatures = [
  'End-to-end encryption',
  'Access control management',
  'Audit trail logging',
  'Automatic backups'
]

const collaborationFeatures = [
  'Real-time document editing',
  'Comment threading',
  'Task assignment',
  'Team chat integration'
]

const caseFeatures = [
  'Case timeline tracking',
  'Document organization',
  'Calendar integration',
  'Deadline management'
]

const scrollToFeatures = () => {
  const featuresSection = document.getElementById('features')
  if (featuresSection) {
    featuresSection.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>

<style scoped>
.hero-section {
  background-image: url('/images/homepage_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  height: 80vh;
  max-height: 800px;
  min-height: 600px;
  display: flex;
  align-items: center;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-section > * {
  position: relative;
  z-index: 2;
}

.feature-animation {
  transition: transform 0.3s ease;
}

.feature-animation:hover {
  transform: translateY(-8px);
}

.feature-row {
  min-height: 500px;
}

.cta-section {
  background: linear-gradient(
    145deg,
    rgba(1, 71, 81, 0.95) 0%,
    rgba(15, 40, 48, 0.95) 100%
  );
  position: relative;
}

.gap-4 {
  gap: 1rem;
}

/* Responsive styles */
@media (max-width: 960px) {
  .text-h2 {
    font-size: 2.5rem !important;
  }

  .text-h3 {
    font-size: 2rem !important;
  }

  .text-h6 {
    font-size: 1.1rem !important;
  }

  .feature-row {
    min-height: unset;
  }

  .pl-md-12,
  .pr-md-12 {
    padding: 0 !important;
  }
}

@media (max-height: 800px) {
  .hero-section {
    height: auto;
    min-height: unset;
    padding: 120px 0;
  }
}

.content-container {
  max-width: 1200px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

@media (max-width: 600px) {
  .content-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
</style> 