import { ref } from 'vue'
import { defineStore } from 'pinia'
import { db } from '../firebase/firebase-config'
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore'

export const useUserTierStore = defineStore('userTier', () => {
  const userTier = ref('free')
  const presentationCount = ref(0)
  const loading = ref(false)
  const error = ref(null)

  // Define tier limits
  const TIER_LIMITS = {
    free: 1,
    premium: Infinity
  }

  // Load user tier and count presentations
  const loadUserTier = async (userId) => {
    if (!userId) return
    
    try {
      loading.value = true
      error.value = null
      
      // Get user's tier status
      const userDoc = await getDoc(doc(db, 'users', userId))
      if (userDoc.exists()) {
        userTier.value = userDoc.data().tier || 'free'
      } else {
        // Create new user with free tier
        await setDoc(doc(db, 'users', userId), {
          tier: 'free',
          createdAt: new Date().toISOString()
        })
        userTier.value = 'free'
      }

      // Count user's presentations
      const presentationsQuery = query(
        collection(db, 'presentations'),
        where('userId', '==', userId)
      )
      const presentationsSnapshot = await getDocs(presentationsQuery)
      presentationCount.value = presentationsSnapshot.size

    } catch (err) {
      console.error('Error loading user tier:', err)
      error.value = err.message
    } finally {
      loading.value = false
    }
  }

  // Check if user can create new presentation
  const canCreatePresentation = () => {
    return presentationCount.value < TIER_LIMITS[userTier.value]
  }

  // Get remaining presentations
  const getRemainingPresentations = () => {
    const limit = TIER_LIMITS[userTier.value]
    if (limit === Infinity) return 'Unlimited'
    return Math.max(0, limit - presentationCount.value)
  }

  // For testing: Manually set user as premium
  const setUserAsPremium = async (userId) => {
    if (!userId) return
    
    try {
      loading.value = true
      error.value = null
      
      await setDoc(doc(db, 'users', userId), {
        tier: 'premium',
        upgradedAt: new Date().toISOString()
      }, { merge: true })
      
      userTier.value = 'premium'
    } catch (err) {
      console.error('Error upgrading user:', err)
      error.value = err.message
    } finally {
      loading.value = false
    }
  }

  // For testing: Reset user to free tier
  const resetToFreeTier = async (userId) => {
    if (!userId) return
    
    try {
      loading.value = true
      error.value = null
      
      await setDoc(doc(db, 'users', userId), {
        tier: 'free',
        resetAt: new Date().toISOString()
      }, { merge: true })
      
      userTier.value = 'free'
    } catch (err) {
      console.error('Error resetting user tier:', err)
      error.value = err.message
    } finally {
      loading.value = false
    }
  }

  return {
    userTier,
    presentationCount,
    loading,
    error,
    loadUserTier,
    canCreatePresentation,
    getRemainingPresentations,
    setUserAsPremium,  // For testing
    resetToFreeTier    // For testing
  }
}) 