<template>
  <v-app>
    <v-app-bar 
      flat 
      color="#1C1C1C"
      class="px-4"
      height="64"
    >
      <router-link 
        to="/" 
        class="d-flex align-center text-decoration-none"
      >
        <logo-icon class="mr-2" />
        <div class="d-flex align-center">
          <span class="text-h6 font-weight-light text-white">LegalLogic</span>
          <v-chip
            color="warning"
            size="small"
            class="ml-2"
            variant="flat"
          >
            In Development
          </v-chip>
        </div>
      </router-link>
      
      <v-spacer></v-spacer>
      
      <div class="navigation-links">
        <template v-if="!isAuthenticated">
          <v-btn 
            v-for="link in publicLinks" 
            :key="link.to" 
            :to="link.to" 
            variant="text" 
            class="nav-link text-none font-weight-regular"
            color="white"
          >
            {{ link.text }}
          </v-btn>
          <v-btn
            to="/login"
            color="primary"
            variant="flat"
            class="text-none ml-4 login-btn"
          >
            Sign In
          </v-btn>
        </template>
        <template v-else>
          <v-btn 
            v-for="link in protectedLinks" 
            :key="link.to" 
            :to="link.to" 
            variant="text" 
            class="text-none font-weight-regular"
            color="white"
          >
            {{ link.text }}
          </v-btn>
          
          <v-menu>
            <template v-slot:activator="{ props }">
              <div class="d-inline-flex align-center ml-4">
                <v-avatar
                  v-bind="props"
                  :color="loading ? 'grey-lighten-3' : undefined"
                  size="32"
                  class="cursor-pointer"
                >
                  <v-img
                    v-if="user?.photoURL && !loading"
                    :src="user.photoURL"
                    :alt="user.displayName"
                    @error="handleAvatarError"
                  >
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          size="20"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                  <span v-else-if="!loading" class="text-subtitle-2">
                    {{ getUserInitials }}
                  </span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                  ></v-progress-circular>
                </v-avatar>
              </div>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title class="text-subtitle-1">
                  {{ user?.displayName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user?.email }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider></v-divider>
              <template v-if="isAdmin">
                <v-list-item
                  to="/admin/feedback"
                  prepend-icon="mdi-message-text"
                  title="User Feedback"
                ></v-list-item>
                <v-divider></v-divider>
              </template>
              <v-list-item
                to="/settings"
                prepend-icon="mdi-cog"
                title="Settings"
              ></v-list-item>
              <v-list-item
                @click="logout"
                prepend-icon="mdi-logout"
                title="Sign Out"
                color="error"
              ></v-list-item>
            </v-list>
          </v-menu>
        </template>
      </div>
    </v-app-bar>

    <v-main>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>

    <v-footer class="text-center d-flex flex-column bg-background">
      <div class="py-4 text-medium-emphasis text-caption">
        &copy; {{ new Date().getFullYear() }} LegalLogic. All rights reserved.
      </div>
    </v-footer>

    <!-- Debug Panel -->
    <v-card
      v-if="showDebugPanel"
      class="debug-panel"
      width="400"
    >
      <v-card-title class="text-subtitle-1 py-2">
        Debug Info
        <v-spacer></v-spacer>
        <v-btn
          icon="mdi-close"
          variant="text"
          density="compact"
          @click="debugVisible = false"
          v-if="debugVisible"
        ></v-btn>
      </v-card-title>
      
      <v-expand-transition>
        <div v-if="debugVisible">
          <v-card-text class="py-2">
            <!-- Evidence Upload Logs -->
            <div class="mb-4">
              <div class="d-flex align-center mb-2">
                <h3 class="text-subtitle-2">Evidence Upload Logs</h3>
                <v-spacer></v-spacer>
                <v-btn
                  size="small"
                  color="error"
                  variant="text"
                  @click="debugStore.clearEvidenceLogs"
                >
                  Clear
                </v-btn>
              </div>
              
              <v-list density="compact" class="bg-grey-lighten-4">
                <v-list-item
                  v-for="(log, index) in debugStore.evidenceUploadLogs"
                  :key="index"
                  :subtitle="new Date(log.timestamp).toLocaleTimeString()"
                >
                  <template v-slot:prepend>
                    <v-icon
                      :color="log.success ? 'success' : 'error'"
                      size="small"
                    >
                      {{ log.success ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                    </v-icon>
                  </template>
                  
                  <v-list-item-title 
                    :class="{'text-error': !log.success}"
                    class="text-caption"
                  >
                    {{ log.message }}
                  </v-list-item-title>
                  
                  <v-list-item-subtitle
                    v-if="log.details"
                    class="text-caption text-medium-emphasis"
                  >
                    {{ log.details }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <!-- Feedback Button -->
    <feedback-button />
  </v-app>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useAuth } from './stores/auth'
import { useDebugStore } from './stores/debug'
import LogoIcon from './components/LogoIcon.vue'
import FeedbackButton from './components/FeedbackButton.vue'
import { doc, getDoc } from 'firebase/firestore'
import { db } from './firebase/firebase-config'

const { user, isAuthenticated, logout, loading, dbError } = useAuth()
const debugStore = useDebugStore()
const debugVisible = ref(false)
const avatarError = ref(false)
const isAdmin = ref(false)

// Safe way to check if we're in development mode
const showDebugPanel = computed(() => {
  try {
    return false
  } catch {
    return false
  }
})

const getUserInitials = computed(() => {
  if (!user.value?.displayName) return '?'
  return user.value.displayName
    .split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase()
    .slice(0, 2)
})

const avatarStatus = computed(() => {
  if (loading.value) return 'Loading...'
  if (avatarError.value) return 'Failed to load'
  if (user.value?.photoURL) return 'Loaded'
  return 'Using Initials'
})

const handleAvatarError = () => {
  avatarError.value = true
}

const publicLinks = [
  { to: '/', text: 'Home' },
  { to: '/features', text: 'Features' },
  { to: '/pricing', text: 'Pricing' }
]

const protectedLinks = [
  { to: '/library', text: 'Library' },
  { to: '/documentation', text: 'Documentation' }
]

// Check admin status when user changes
watch(() => user.value?.uid, async (uid) => {
  if (!user.value?.uid) return false
  try {
    const userDoc = await getDoc(doc(db, 'users', user.value.uid))
    isAdmin.value = userDoc.exists() && userDoc.data().isAdmin === true
  } catch {
    isAdmin.value = false
  }
}, { immediate: true })
</script>

<style scoped>
.navigation-links {
  display: flex;
  gap: 32px;
  align-items: center;
}

.nav-link {
  position: relative;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background: var(--v-primary-base);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover {
  opacity: 1;
}

.nav-link:hover::after {
  width: 100%;
}

.login-btn {
  border-radius: 28px;
  min-width: 100px;
  transition: transform 0.3s ease;
}

.login-btn:hover {
  transform: translateY(-2px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.debug-panel {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  opacity: 0.9;
}

/* Container max-width for large screens */
:deep(.v-container) {
  max-width: 1440px !important;
}

/* Navigation adjustments for mobile */
.navigation-links {
  display: flex;
  gap: 32px;
  align-items: center;
}

@media (max-width: 600px) {
  .nav-link {
    font-size: 0.875rem;
  }
  
  .login-btn {
    min-width: unset;
    padding: 0 16px;
  }
}

/* Adjust spacing for larger screens */
@media (min-width: 1904px) {
  :deep(.v-container) {
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style> 