import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase/firebase-config'
import Home from '../views/Home.vue'
import Features from '../views/Features.vue'
import Pricing from '../views/Pricing.vue'
import Checkout from '../views/Checkout.vue'
import Library from '../views/Library.vue'
import Settings from '../views/Settings.vue'
import Documentation from '../views/Documentation.vue'
import Login from '../views/Login.vue'
import NewPresentation from '../views/NewPresentation.vue'
import NotFound from '../views/NotFound.vue'
import AdminFeedback from '../views/AdminFeedback.vue'
import { getDoc, doc } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/library',
    name: 'Library',
    component: Library,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation,
    meta: { requiresAuth: true }
  },
  {
    path: '/new-presentation',
    name: 'NewPresentation',
    component: NewPresentation,
    meta: { requiresAuth: true }
  },
  {
    path: '/presentation/:id',
    name: 'Presentation',
    component: () => import('../views/Presentation.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/presentation/:id/edit',
    name: 'EditPresentation',
    component: () => import('../views/EditPresentation.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/feedback',
    name: 'AdminFeedback',
    component: () => import('../views/AdminFeedback.vue'),
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { top: 0, behavior: 'smooth' }
  }
})

// Navigation guard
router.beforeEach(async (to, from, next) => {
  const currentUser = auth.currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAdmin) {
    // Check if user is admin
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid))
    if (!userDoc.exists() || !userDoc.data().isAdmin) {
      next('/')
    } else {
      next()
    }
  } else if (to.path === '/login' && currentUser) {
    next('/library')
  } else {
    next()
  }
})

export default router 