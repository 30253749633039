<template>
  <div class="feedback-container">
    <!-- Feedback Button -->
    <v-btn
      color="primary"
      rounded="pill"
      class="feedback-button elevation-4"
      @click="dialog = true"
    >
      <v-icon start>mdi-message-draw</v-icon>
      Feedback
    </v-btn>

    <!-- Feedback Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5 pb-2">
          Share Your Feedback
        </v-card-title>
        
        <v-card-subtitle class="pb-4">
          Help us improve LegalLogic with your suggestions
        </v-card-subtitle>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitFeedback">
            <!-- Feedback Type -->
            <v-select
              v-model="feedbackType"
              :items="feedbackTypes"
              label="Type of Feedback"
              required
              :rules="[v => !!v || 'Please select a feedback type']"
              class="mb-4"
            ></v-select>

            <!-- Feedback Message -->
            <v-textarea
              v-model="message"
              label="Your Feedback"
              required
              :rules="[v => !!v || 'Please enter your feedback']"
              counter
              maxlength="500"
              class="mb-4"
            ></v-textarea>

            <!-- Email (optional for non-authenticated users) -->
            <v-text-field
              v-if="!isAuthenticated"
              v-model="email"
              label="Email (optional)"
              type="email"
              :rules="emailRules"
              hint="We'll only use this to follow up if needed"
              persistent-hint
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
            variant="text"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :loading="submitting"
            :disabled="!valid"
            @click="submitFeedback"
          >
            Submit Feedback
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success Snackbar -->
    <v-snackbar
      v-model="showSuccess"
      color="success"
      timeout="3000"
    >
      Thank you for your feedback!
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useAuth } from '../stores/auth'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'

const { isAuthenticated, user } = useAuth()

const dialog = ref(false)
const valid = ref(false)
const submitting = ref(false)
const showSuccess = ref(false)
const form = ref(null)

// Form fields
const feedbackType = ref(null)
const message = ref('')
const email = ref('')

// Form validation
const emailRules = [
  v => !v || /.+@.+\..+/.test(v) || 'Please enter a valid email'
]

const feedbackTypes = [
  'Suggestion',
  'Bug Report',
  'Feature Request',
  'General Feedback'
]

const submitFeedback = async () => {
  if (!form.value.validate()) return

  try {
    submitting.value = true
    
    await addDoc(collection(db, 'feedback'), {
      type: feedbackType.value,
      message: message.value,
      email: isAuthenticated ? user.value?.email : email.value,
      userId: user.value?.uid || null,
      createdAt: new Date().toISOString(),
      path: window.location.pathname,
      userAgent: navigator.userAgent
    })

    showSuccess.value = true
    dialog.value = false
    resetForm()
  } catch (error) {
    console.error('Error submitting feedback:', error)
  } finally {
    submitting.value = false
  }
}

const resetForm = () => {
  feedbackType.value = null
  message.value = ''
  email.value = ''
  form.value?.reset()
}
</script>

<style scoped>
.feedback-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100;
}

.feedback-button {
  transition: transform 0.2s ease;
}

.feedback-button:hover {
  transform: translateY(-2px);
}
</style> 