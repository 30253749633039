<template>
  <div class="library">
    <v-container class="py-8">
      <!-- Welcome Banner -->
      <v-slide-y-transition>
        <v-alert
          v-if="showWelcomeBanner"
          color="primary"
          variant="tonal"
          closable
          class="mb-6"
          @click:close="dismissWelcomeBanner"
        >
          <div class="d-flex align-center">
            <div>
              <p class="text-h6 mb-1">Welcome to LegalPad! 👋</p>
              <p class="text-body-2 mb-0">
                Create powerful presentations for your legal cases. Organize evidence, track events, and present with confidence.
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              variant="flat"
              @click="createNewPresentation"
            >
              Get Started
            </v-btn>
          </div>
        </v-alert>
      </v-slide-y-transition>

      <v-row>
        <v-col cols="12">
          <!-- Update the header section -->
          <div class="mb-8">
            <!-- Title and Welcome Message -->
            <div class="mb-4">
              <h1 class="text-h4 font-weight-light mb-2">My Library</h1>
              <p class="text-h6 text-medium-emphasis" v-if="auth?.user?.value">
                Welcome back, {{ auth.user.value.displayName }}! Here's your latest activity and presentations.
              </p>
            </div>

            <!-- Search and Actions Bar -->
            <div class="d-flex align-center gap-4">
              <!-- View Mode Toggle -->
              <v-btn-toggle
                v-if="documents.length > 0"
                v-model="viewMode"
                color="primary"
                rounded="lg"
                mandatory
              >
                <v-btn 
                  value="grid" 
                  variant="text"
                  v-tooltip="'Grid View'"
                >
                  <v-icon>mdi-grid</v-icon>
                </v-btn>
                <v-btn 
                  value="list" 
                  variant="text"
                  v-tooltip="'List View'"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </v-btn-toggle>

              <!-- Search Field -->
              <v-text-field
                v-if="documents.length > 0"
                v-model="searchQuery"
                placeholder="Search presentations by title, date, or keyword..."
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                density="comfortable"
                hide-details
                class="search-field flex-grow-1"
                @update:model-value="handleSearch"
                clearable
              >
                <!-- Filter Menu -->
                <template v-slot:append>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        icon="mdi-filter"
                        variant="text"
                        size="small"
                        :color="hasActiveFilters ? 'primary' : undefined"
                      ></v-btn>
                    </template>
                    <v-list>
                      <v-list-subheader>Filter by Status</v-list-subheader>
                      <v-list-item
                        v-for="status in ['all', 'draft', 'active']"
                        :key="status"
                        @click="toggleStatusFilter(status)"
                      >
                        <template v-slot:prepend>
                          <v-checkbox
                            v-model="statusFilters"
                            :value="status"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <v-list-item-title class="text-capitalize">
                          {{ status === 'all' ? 'Show All' : status }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-text-field>

              <!-- Sort Menu -->
              <v-menu v-if="documents.length > 0">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="text"
                    prepend-icon="mdi-sort"
                    class="text-none"
                  >
                    {{ sortOptions[currentSort].label }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(option, key) in sortOptions"
                    :key="key"
                    :value="key"
                    @click="currentSort = key"
                  >
                    <v-list-item-title>{{ option.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

          <!-- Loading State -->
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            class="mb-4"
          ></v-progress-linear>

          <!-- Error Alert -->
          <v-alert
            v-if="error"
            type="error"
            class="mb-4"
            closable
          >
            {{ error }}
          </v-alert>
          
          <!-- Connection Warning -->
          <v-alert
            v-if="!connectionState"
            type="warning"
            class="mb-4"
            icon="mdi-wifi-off"
          >
            Connection issues detected. Some features may be limited.
          </v-alert>

          <!-- Empty State -->
          <v-card
            v-if="!loading && filteredDocuments.length === 0"
            class="empty-state pa-16 text-center"
            flat
          >
            <v-img
              src="/images/empty-library.svg"
              class="mx-auto mb-8"
              max-width="240"
              alt="Empty library illustration"
            ></v-img>
            
            <h2 class="text-h5 font-weight-medium mb-2">
              {{ documents.length === 0 ? 
                "Let's create your first presentation!" : 
                "No presentations found" }}
            </h2>
            
            <p class="text-medium-emphasis mb-8" v-if="documents.length === 0">
              Start organizing your legal cases with powerful presentations
            </p>
            <p class="text-medium-emphasis mb-8" v-else>
              Try adjusting your search or create a new presentation
            </p>

            <div class="d-flex justify-center gap-4 flex-wrap">
              <v-btn
                color="primary"
                size="large"
                prepend-icon="mdi-plus"
                :loading="creating"
                :disabled="!connectionState"
                @click="createNewPresentation"
              >
                Create Presentation
              </v-btn>
              <v-btn
                variant="outlined"
                size="large"
                prepend-icon="mdi-import"
                :disabled="!connectionState"
                @click="importPresentation"
              >
                Import Presentation
              </v-btn>
              <v-btn
                variant="outlined"
                size="large"
                prepend-icon="mdi-file-document-multiple"
                :disabled="!connectionState"
                @click="exploreTemplates"
              >
                Explore Templates
              </v-btn>
            </div>
          </v-card>

          <!-- Grid View -->
          <v-row v-if="viewMode === 'grid'">
            <!-- New Presentation Card - Always First -->
            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  :elevation="isHovering ? 4 : 1"
                  class="h-100 transition-swing new-presentation-card"
                  @click="createNewPresentation"
                  :loading="creating"
                  :disabled="!connectionState"
                >
                  <div class="d-flex flex-column align-center justify-center fill-height pa-8">
                    <v-icon
                      size="64"
                      color="primary"
                      class="mb-4"
                    >
                      mdi-plus-circle
                    </v-icon>
                    <h3 class="text-h6 text-primary text-center mb-2">
                      Create New Presentation
                    </h3>
                    <p class="text-body-2 text-medium-emphasis text-center">
                      Start organizing your legal case with a new presentation
                    </p>
                  </div>
                </v-card>
              </v-hover>
            </v-col>

            <!-- Existing Presentations -->
            <v-col 
              v-for="doc in sortedAndFilteredDocuments" 
              :key="doc.id" 
              cols="12" 
              sm="6" 
              md="4"
            >
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  :elevation="isHovering ? 4 : 1"
                  class="h-100 transition-swing"
                  @click="handleDocumentClick(doc)"
                >
                  <!-- Featured Image -->
                  <v-img
                    v-if="doc.featuredImage"
                    :src="doc.featuredImage"
                    :alt="doc.title"
                    height="160"
                    cover
                  >
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height bg-surface">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                  <div 
                    v-else 
                    class="featured-image-placeholder d-flex align-center justify-center bg-surface"
                    style="height: 160px;"
                  >
                    <v-icon
                      :icon="getDocumentIcon(doc.type)"
                      :color="getDocumentColor(doc.status)"
                      size="48"
                    ></v-icon>
                  </div>

                  <v-card-text>
                    <div class="d-flex justify-center gap-2 my-2">
                      <v-btn
                        icon="mdi-pencil"
                        variant="text"
                        color="primary"
                        size="small"
                        @click.stop="editDocument(doc)"
                        v-tooltip="'Edit Presentation'"
                      ></v-btn>
                      <v-btn
                        icon="mdi-eye"
                        variant="text"
                        color="primary"
                        size="small"
                        @click.stop="viewDocument(doc)"
                        v-tooltip="'View Presentation'"
                      ></v-btn>
                      <v-btn
                        icon="mdi-delete"
                        variant="text"
                        color="error"
                        size="small"
                        @click.stop="confirmDelete(doc)"
                        v-tooltip="'Delete Presentation'"
                      ></v-btn>
                    </div>

                    <div class="d-flex align-center mb-2">
                      <div class="text-subtitle-1 font-weight-medium text-truncate">
                        {{ doc.title || 'Untitled Presentation' }}
                      </div>
                      <v-spacer></v-spacer>
                      <v-chip
                        size="small"
                        :color="doc.status === 'draft' ? 'grey' : 'success'"
                        variant="flat"
                      >
                        {{ doc.status === 'draft' ? 'Draft' : 'Active' }}
                      </v-chip>
                    </div>
                    
                    <div class="text-caption text-medium-emphasis d-flex align-center">
                      <v-icon
                        size="small"
                        class="mr-1"
                      >
                        mdi-clock-outline
                      </v-icon>
                      {{ formatDate(doc.updatedAt || doc.createdAt) }}
                    </div>
                    
                    <div v-if="doc.description" class="text-caption text-truncate mt-2">
                      {{ doc.description }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>

          <!-- List View -->
          <v-table v-else>
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Last Modified</th>
                <th>Description</th>
                <th class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <!-- New Presentation Row - Top -->
              <tr class="new-presentation-row">
                <td colspan="5">
                  <v-btn
                    block
                    color="primary"
                    variant="text"
                    height="64"
                    :loading="creating"
                    :disabled="!connectionState"
                    @click="createNewPresentation"
                  >
                    <div class="d-flex align-center">
                      <v-icon size="24" class="mr-2">mdi-plus-circle</v-icon>
                      <span>Create New Presentation</span>
                    </div>
                  </v-btn>
                </td>
              </tr>

              <!-- Existing Presentations -->
              <tr 
                v-for="doc in sortedAndFilteredDocuments" 
                :key="doc.id"
                class="list-row"
              >
                <td>
                  <div class="d-flex align-center">
                    <div class="list-image-container mr-3">
                      <v-img
                        v-if="doc.featuredImage"
                        :src="doc.featuredImage"
                        :alt="doc.title"
                        width="60"
                        height="40"
                        cover
                        class="rounded"
                      >
                        <template v-slot:placeholder>
                          <div class="d-flex align-center justify-center fill-height bg-surface">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              size="20"
                            ></v-progress-circular>
                          </div>
                        </template>
                      </v-img>
                      <div 
                        v-else 
                        class="list-image-placeholder d-flex align-center justify-center bg-surface rounded"
                      >
                        <v-icon
                          :icon="getDocumentIcon(doc.type)"
                          :color="getDocumentColor(doc.status)"
                          size="24"
                        ></v-icon>
                      </div>
                    </div>
                    {{ doc.title || 'Untitled Presentation' }}
                  </div>
                </td>
                <td>
                  <v-chip
                    size="small"
                    :color="doc.status === 'draft' ? 'grey' : 'success'"
                    variant="flat"
                  >
                    {{ doc.status === 'draft' ? 'Draft' : 'Active' }}
                  </v-chip>
                </td>
                <td>{{ formatDate(doc.updatedAt || doc.createdAt) }}</td>
                <td class="text-truncate" style="max-width: 200px;">
                  {{ doc.description || 'No description' }}
                </td>
                <td class="text-right">
                  <v-btn
                    icon="mdi-pencil"
                    variant="text"
                    size="small"
                    color="primary"
                    class="mx-1"
                    @click="editDocument(doc)"
                    v-tooltip="'Edit Presentation'"
                  ></v-btn>
                  <v-btn
                    icon="mdi-eye"
                    variant="text"
                    size="small"
                    color="primary"
                    class="mx-1"
                    @click="viewDocument(doc)"
                    v-tooltip="'View Presentation'"
                  ></v-btn>
                  <v-btn
                    icon="mdi-delete"
                    variant="text"
                    size="small"
                    color="error"
                    class="mx-1"
                    @click="confirmDelete(doc)"
                    v-tooltip="'Delete Presentation'"
                  ></v-btn>
                </td>
              </tr>

              <!-- New Presentation Row - Bottom -->
              <tr class="new-presentation-row" v-if="sortedAndFilteredDocuments.length > 0">
                <td colspan="5">
                  <v-btn
                    block
                    color="primary"
                    variant="text"
                    height="64"
                    :loading="creating"
                    :disabled="!connectionState"
                    @click="createNewPresentation"
                  >
                    <div class="d-flex align-center">
                      <v-icon size="24" class="mr-2">mdi-plus-circle</v-icon>
                      <span>Create New Presentation</span>
                    </div>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="showDeleteDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Delete Presentation</v-card-title>
        <v-card-text>
          Are you sure you want to delete this presentation? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="text"
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDocument"
            :loading="deleting"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Toast Notifications -->
    <v-snackbar
      v-model="showToast"
      :color="toastType"
      location="top"
      :timeout="3000"
    >
      {{ toastMessage }}
      <template v-slot:actions>
        <v-btn
          variant="text"
          @click="showToast = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useAuth } from '../stores/auth'
import { useUserTierStore } from '../stores/userTier'
import { db } from '../firebase/firebase-config'
import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc, orderBy, enableNetwork, disableNetwork } from 'firebase/firestore'
import { useRouter } from 'vue-router'

const auth = useAuth()
const userTierStore = useUserTierStore()
const router = useRouter()

const viewMode = ref('grid')
const searchQuery = ref('')
const documents = ref([])
const loading = ref(true)
const creating = ref(false)
const error = ref(null)
let unsubscribe = null

// Add new refs for enhanced functionality
const showWelcomeBanner = ref(!localStorage.getItem('welcomeBannerDismissed'))
const showToast = ref(false)
const toastMessage = ref('')
const toastType = ref('success')
const recentActivity = ref([])
const currentSort = ref('newest')
const statusFilters = ref(['all'])
const showDeleteDialog = ref(false)
const deleting = ref(false)
const documentToDelete = ref(null)

// Add sort options
const sortOptions = {
  newest: { 
    label: 'Newest First', 
    sort: (a, b) => {
      const dateA = new Date(a.createdAt || 0)
      const dateB = new Date(b.createdAt || 0)
      return dateB - dateA
    }
  },
  oldest: { 
    label: 'Oldest First', 
    sort: (a, b) => {
      const dateA = new Date(a.createdAt || 0)
      const dateB = new Date(b.createdAt || 0)
      return dateA - dateB
    }
  },
  title: { 
    label: 'Title A-Z', 
    sort: (a, b) => {
      const titleA = (a.title || '').toLowerCase()
      const titleB = (b.title || '').toLowerCase()
      return titleA.localeCompare(titleB)
    }
  }
}

// Methods for new functionality
const dismissWelcomeBanner = () => {
  showWelcomeBanner.value = false
  localStorage.setItem('welcomeBannerDismissed', 'true')
}

const showNotification = (message, type = 'success') => {
  toastMessage.value = message
  toastType.value = type
  showToast.value = true
}

const createNewPresentation = () => {
  router.push('/new-presentation')
}

const importPresentation = () => {
  // Implement import functionality
  showNotification('Import feature coming soon!', 'info')
}

const exploreTemplates = () => {
  // Implement templates functionality
  showNotification('Templates feature coming soon!', 'info')
}

// Add connection state management
const connectionState = ref(true)

// Add connection check function
const checkConnection = async () => {
  try {
    await enableNetwork(db)
    connectionState.value = true
  } catch (error) {
    console.error('Connection error:', error)
    connectionState.value = false
  }
}

// Update onMounted
onMounted(async () => {
  await checkConnection() // Check connection first
  
  if (!auth?.user?.value?.uid) {
    router.push('/login')
    return
  }
  
  // Load user tier data
  await userTierStore.loadUserTier(auth?.user?.value?.uid)
  
  // Load presentations
  loading.value = true
  error.value = null

  const q = query(
    collection(db, 'presentations'),
    where('userId', '==', auth?.user?.value?.uid),
    orderBy('createdAt', 'desc')
  )

  unsubscribe = onSnapshot(q, 
    (snapshot) => {
      documents.value = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      loading.value = false
      error.value = null
    },
    (err) => {
      console.error('Error loading presentations:', err)
      if (err.code === 'failed-precondition' && err.message.includes('index')) {
        error.value = 'System is being configured. Please try again in a few minutes.'
      } else {
        error.value = 'Failed to load presentations: ' + err.message
      }
      loading.value = false
    }
  )

  // Load recent activity (placeholder data)
  recentActivity.value = [
    {
      id: 1,
      title: 'Getting Started Guide',
      description: 'Learn how to create effective legal presentations',
      icon: 'mdi-book-open-page-variant',
      date: new Date()
    },
    {
      id: 2,
      title: 'Sample Templates',
      description: 'Explore our collection of presentation templates',
      icon: 'mdi-file-document-multiple',
      date: new Date()
    }
  ]
})

// Add periodic connection check
const connectionCheckInterval = setInterval(checkConnection, 30000) // Check every 30 seconds

// Clean up on unmount
onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe()
  }
  clearInterval(connectionCheckInterval)
})

// Add computed properties
const hasActiveFilters = computed(() => {
  return statusFilters.value.length > 0 && !statusFilters.value.includes('all')
})

const filteredDocuments = computed(() => {
  if (!documents.value) return []
  
  let filtered = [...documents.value]
  
  // Apply search filter
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    filtered = filtered.filter(doc => 
      (doc.title || '').toLowerCase().includes(query) ||
      (doc.description || '').toLowerCase().includes(query)
    )
  }
  
  // Apply status filters
  if (statusFilters.value.includes('all')) {
    return filtered
  }
  
  // If specific statuses are selected, filter by those statuses
  return filtered.filter(doc => {
    // Map 'active' to 'live' for consistency
    const statusesToCheck = statusFilters.value.map(s => s === 'active' ? 'live' : s)
    return statusesToCheck.includes(doc.status)
  })
})

const sortedAndFilteredDocuments = computed(() => {
  const docs = [...filteredDocuments.value]
  const sortFn = sortOptions[currentSort.value].sort
  return docs.sort(sortFn)
})

const handleSearch = (query) => {
  searchQuery.value = query
}

const confirmDelete = (doc) => {
  documentToDelete.value = doc
  showDeleteDialog.value = true
}

const deleteDocument = async () => {
  if (!documentToDelete.value) return
  
  try {
    deleting.value = true
    await deleteDoc(doc(db, 'presentations', documentToDelete.value.id))
    showToast.value = true
    toastMessage.value = 'Presentation deleted successfully'
    toastType.value = 'success'
  } catch (error) {
    console.error('Error deleting presentation:', error)
    showToast.value = true
    toastMessage.value = 'Failed to delete presentation'
    toastType.value = 'error'
  } finally {
    deleting.value = false
    showDeleteDialog.value = false
    documentToDelete.value = null
  }
}

const editDocument = (doc) => {
  router.push(`/presentation/${doc.id}/edit`)
}

const handleDocumentClick = (doc) => {
  if (doc.status === 'draft') {
    // If it's a draft, go to edit mode
    router.push(`/presentation/${doc.id}/edit`)
  } else {
    // If it's active/published, go to view mode
    router.push(`/presentation/${doc.id}`)
  }
}

const getDocumentIcon = (type) => {
  const icons = {
    'Document': 'mdi-file-document-outline',
    'Contract': 'mdi-file-document-outline',
    'Brief': 'mdi-file-document-outline',
    'Terms': 'mdi-gavel',
    'default': 'mdi-file-outline'
  }
  return icons[type] || icons.default
}

const getDocumentColor = (type) => {
  const colors = {
    'Document': 'blue',
    'Contract': 'blue',
    'Brief': 'purple',
    'Terms': 'deep-purple',
    'default': 'grey'
  }
  return colors[type] || colors.default
}

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

// Add methods
const toggleStatusFilter = (status) => {
  if (status === 'all') {
    statusFilters.value = ['all']
  } else {
    // Remove 'all' if it exists
    statusFilters.value = statusFilters.value.filter(s => s !== 'all')
    
    const index = statusFilters.value.indexOf(status)
    if (index === -1) {
      statusFilters.value.push(status)
    } else {
      statusFilters.value.splice(index, 1)
    }
    
    // If no filters are selected, show all
    if (statusFilters.value.length === 0) {
      statusFilters.value = ['all']
    }
  }
}

const viewDocument = (doc) => {
  router.push(`/presentation/${doc.id}`)
}
</script>

<style scoped>
:deep(.v-btn-toggle) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  border-radius: 8px;
}

:deep(.v-btn-toggle .v-btn) {
  border: none !important;
}

.v-card {
  transition: transform 0.2s ease;
}

.v-card:hover {
  transform: translateY(-2px);
}

:deep(.v-field) {
  border-radius: 8px;
}

:deep(.v-field__input) {
  min-height: 40px !important;
}

.empty-state {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
}

.gap-4 {
  gap: 1rem;
}

/* Accessibility focus styles */
:deep(.v-btn:focus-visible) {
  outline: 2px solid var(--v-primary-base);
  outline-offset: 2px;
}

.new-presentation-card {
  border: 2px dashed rgba(var(--v-theme-primary), 0.4);
  background: rgba(var(--v-theme-primary), 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
}

.new-presentation-card:hover {
  border-color: rgb(var(--v-theme-primary));
  background: rgba(var(--v-theme-primary), 0.1);
}

.new-presentation-row {
  background: rgba(var(--v-theme-primary), 0.05);
}

.new-presentation-row:hover {
  background: rgba(var(--v-theme-primary), 0.1);
}
</style> 