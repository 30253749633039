<template>
  <div class="settings">
    <v-container class="py-8">
      <h1 class="text-h4 font-weight-light mb-6">Settings</h1>
      
      <v-row>
        <v-col cols="12" md="8">
          <!-- Profile Card -->
          <v-card class="mb-6">
            <v-card-title class="d-flex align-center">
              Profile Settings
              <v-spacer></v-spacer>
              <v-btn
                v-if="hasChanges"
                color="primary"
                :loading="saving"
                @click="saveChanges"
              >
                Save Changes
              </v-btn>
            </v-card-title>

            <v-card-text>
              <div class="d-flex align-center mb-6">
                <v-avatar size="80" class="mr-4">
                  <v-img
                    v-if="userData?.photoURL"
                    :src="userData.photoURL"
                    :alt="userData?.displayName"
                  >
                    <template v-slot:placeholder>
                      <v-avatar
                        :color="loading ? 'grey-lighten-3' : 'primary'"
                        size="80"
                      >
                        <span class="text-h4 font-weight-light">
                          {{ getUserInitials }}
                        </span>
                      </v-avatar>
                    </template>
                  </v-img>
                  <v-avatar
                    v-else
                    :color="loading ? 'grey-lighten-3' : 'primary'"
                    size="80"
                  >
                    <span class="text-h4 font-weight-light">
                      {{ getUserInitials }}
                    </span>
                  </v-avatar>
                </v-avatar>
                <div>
                  <div class="text-h6">{{ userData?.displayName }}</div>
                  <div class="text-subtitle-2 text-medium-emphasis">
                    {{ userData?.email }}
                  </div>
                </div>
              </div>

              <v-form ref="form" @submit.prevent="saveChanges">
                <v-text-field
                  v-model="formData.displayName"
                  label="Display Name"
                  variant="outlined"
                  class="mb-4"
                  :rules="[v => !!v || 'Name is required']"
                ></v-text-field>

                <v-text-field
                  :value="userData?.email"
                  label="Email"
                  variant="outlined"
                  class="mb-4"
                  disabled
                  hint="Email cannot be changed when using Google authentication"
                  persistent-hint
                ></v-text-field>

                <v-select
                  v-model="formData.timezone"
                  :items="timezones"
                  label="Timezone"
                  variant="outlined"
                  class="mb-4"
                ></v-select>

                <v-text-field
                  v-model="formData.jobTitle"
                  label="Job Title"
                  variant="outlined"
                  class="mb-4"
                  placeholder="e.g., Attorney, Legal Assistant"
                ></v-text-field>

                <v-textarea
                  v-model="formData.bio"
                  label="Bio"
                  variant="outlined"
                  class="mb-4"
                  rows="3"
                  placeholder="Tell us about yourself"
                ></v-textarea>
              </v-form>
            </v-card-text>
          </v-card>

          <!-- Preferences Card -->
          <v-card>
            <v-card-title>Preferences</v-card-title>
            <v-card-text>
              <v-switch
                v-model="formData.preferences.emailNotifications"
                label="Email Notifications"
                class="mb-2"
              ></v-switch>
              
              <v-switch
                v-model="formData.preferences.desktopNotifications"
                label="Desktop Notifications"
                class="mb-2"
              ></v-switch>

              <v-switch
                v-model="formData.preferences.weeklyDigest"
                label="Weekly Activity Digest"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Account Info Sidebar -->
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title>Account Info</v-card-title>
            <v-card-text>
              <div class="text-caption mb-2">
                <div class="font-weight-medium">Member Since</div>
                <div>{{ formatDate(userData?.createdAt) }}</div>
              </div>
              <div class="text-caption mb-2">
                <div class="font-weight-medium">Last Login</div>
                <div>{{ formatDate(userData?.lastLogin) }}</div>
              </div>
              <div class="text-caption">
                <div class="font-weight-medium">Account ID</div>
                <div class="text-truncate">{{ userData?.uid }}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAuth } from '../stores/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'

const { user } = useAuth()
const form = ref(null)
const loading = ref(true)
const saving = ref(false)
const userData = ref(null)

const formData = ref({
  displayName: '',
  timezone: 'UTC',
  jobTitle: '',
  bio: '',
  preferences: {
    emailNotifications: true,
    desktopNotifications: false,
    weeklyDigest: true
  }
})

const timezones = [
  'UTC',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'Europe/London',
  'Europe/Paris',
  'Asia/Tokyo'
]

// Computed Properties
const getUserInitials = computed(() => {
  if (!userData.value?.displayName) return '?'
  return userData.value.displayName
    .split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase()
    .slice(0, 2)
})

const hasChanges = computed(() => {
  if (!userData.value) return false
  return JSON.stringify(formData.value) !== JSON.stringify({
    displayName: userData.value.displayName,
    timezone: userData.value.timezone || 'UTC',
    jobTitle: userData.value.jobTitle || '',
    bio: userData.value.bio || '',
    preferences: userData.value.preferences || {
      emailNotifications: true,
      desktopNotifications: false,
      weeklyDigest: true
    }
  })
})

// Methods
const loadUserData = async () => {
  if (!user.value?.uid) return
  
  try {
    loading.value = true
    const userDoc = await getDoc(doc(db, 'users', user.value.uid))
    if (userDoc.exists()) {
      userData.value = userDoc.data()
      // Initialize form data
      formData.value = {
        displayName: userData.value.displayName,
        timezone: userData.value.timezone || 'UTC',
        jobTitle: userData.value.jobTitle || '',
        bio: userData.value.bio || '',
        preferences: userData.value.preferences || {
          emailNotifications: true,
          desktopNotifications: false,
          weeklyDigest: true
        }
      }
    }
  } catch (error) {
    console.error('Error loading user data:', error)
  } finally {
    loading.value = false
  }
}

const saveChanges = async () => {
  if (!user.value?.uid) return
  
  try {
    saving.value = true
    const userRef = doc(db, 'users', user.value.uid)
    await setDoc(userRef, {
      ...userData.value,
      ...formData.value,
      updatedAt: new Date().toISOString()
    }, { merge: true })
    
    // Update local user data
    userData.value = {
      ...userData.value,
      ...formData.value
    }
  } catch (error) {
    console.error('Error saving changes:', error)
  } finally {
    saving.value = false
  }
}

const formatDate = (dateString) => {
  if (!dateString) return 'N/A'
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// Lifecycle
onMounted(() => {
  loadUserData()
})
</script>

<style scoped>
.v-card {
  border: 1px solid rgba(0,0,0,0.1);
}
</style> 