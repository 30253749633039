import { ref, computed } from 'vue'
import { auth, googleProvider, db } from '../firebase/firebase-config'
import { signInWithPopup, signOut } from 'firebase/auth'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'

// Auth state
const user = ref(null)
const loading = ref(true)
const error = ref(null)
const dbError = ref(null)
const isConnected = ref(true) // Default to true since we're using Firestore

// Initialize auth state with database sync
const initAuth = async (userData) => {
  if (!userData) {
    user.value = null
    loading.value = false
    return
  }

  try {
    // Create base user object
    const baseUser = {
      uid: userData.uid,
      email: userData.email,
      displayName: userData.displayName,
      photoURL: generateInitialsAvatar(userData.displayName)
    }

    // Set initial user state
    user.value = baseUser

    // Try to sync with Firestore
    try {
      const userRef = doc(db, 'users', userData.uid)
      const userDoc = await getDoc(userRef)
      
      if (!userDoc.exists()) {
        // User doesn't exist in database, create them
        await setDoc(userRef, {
          ...baseUser,
          createdAt: new Date().toISOString(),
          lastLogin: new Date().toISOString()
        })
        console.log('Created new user in Firestore')
      } else {
        // Update last login
        await setDoc(userRef, {
          ...userDoc.data(),
          lastLogin: new Date().toISOString()
        }, { merge: true })
        console.log('Updated existing user in Firestore')
      }
      isConnected.value = true
    } catch (e) {
      console.error('Firestore sync error:', e)
      dbError.value = e.message
      isConnected.value = navigator.onLine
    }

  } catch (e) {
    console.error('Error initializing user:', e)
    error.value = e.message
  } finally {
    loading.value = false
  }
}

// Auth actions
export function useAuth() {
  const router = useRouter()

  // Monitor online/offline status
  if (typeof window !== 'undefined') {
    window.addEventListener('online', () => {
      isConnected.value = true
    })
    window.addEventListener('offline', () => {
      isConnected.value = false
    })
  }

  const signInWithGoogle = async () => {
    try {
      loading.value = true
      error.value = null
      dbError.value = null
      
      // Check connection before attempting sign in
      if (!navigator.onLine) {
        throw new Error('No internet connection. Please check your network and try again.');
      }
      
      const result = await signInWithPopup(auth, googleProvider)
      
      try {
        const userRef = doc(db, 'users', result.user.uid)
        // Add retry logic for Firestore operations
        let retries = 3;
        while (retries > 0) {
          try {
            await setDoc(userRef, {
              uid: result.user.uid,
              email: result.user.email,
              displayName: result.user.displayName,
              photoURL: result.user.photoURL,
              createdAt: new Date().toISOString(),
              lastLogin: new Date().toISOString()
            }, { merge: true })
            break;
          } catch (e) {
            retries--;
            if (retries === 0) throw e;
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        
        console.log('Successfully wrote user data to Firestore')
        isConnected.value = true
      } catch (e) {
        console.error('Failed to write user data:', e)
        dbError.value = e.message
        isConnected.value = navigator.onLine
      }

      router.push('/library')
    } catch (e) {
      error.value = e.message
      console.error('Sign in error:', e)
    } finally {
      loading.value = false
    }
  }

  const logout = async () => {
    try {
      loading.value = true
      if (user.value?.uid) {
        try {
          // Update last logout time
          const userRef = doc(db, 'users', user.value.uid)
          await setDoc(userRef, {
            lastLogout: new Date().toISOString()
          }, { merge: true })
        } catch (e) {
          console.warn('Failed to update logout time:', e)
        }
      }
      await signOut(auth)
      router.push('/')
    } catch (e) {
      error.value = e.message
      console.error('Sign out error:', e)
    } finally {
      loading.value = false
    }
  }

  const clearError = () => {
    error.value = null
    dbError.value = null
  }

  // Initialize auth state
  auth.onAuthStateChanged((userData) => {
    initAuth(userData)
  })

  return {
    user,
    loading,
    error,
    dbError,
    isAuthenticated: computed(() => !!user.value),
    isConnected,
    signInWithGoogle,
    logout,
    clearError
  }
}

// Helper function for avatar
function generateInitialsAvatar(name) {
  const initials = name
    ?.split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase()
    .slice(0, 2) || 'U'
  
  return `data:image/svg+xml,${encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#007AFF"/>
      <text x="20" y="20" text-anchor="middle" dy=".3em" 
        fill="white" font-family="Arial" font-size="16">${initials}</text>
    </svg>
  `)}`
} 