import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useDebugStore = defineStore('debug', () => {
  const evidenceUploadLogs = ref([])
  const maxLogs = 10 // Keep last 10 logs

  const addEvidenceLog = (log) => {
    const timestamp = new Date().toISOString()
    evidenceUploadLogs.value.unshift({
      timestamp,
      ...log
    })

    // Keep only the last maxLogs entries
    if (evidenceUploadLogs.value.length > maxLogs) {
      evidenceUploadLogs.value = evidenceUploadLogs.value.slice(0, maxLogs)
    }
  }

  const clearEvidenceLogs = () => {
    evidenceUploadLogs.value = []
  }

  return {
    evidenceUploadLogs,
    addEvidenceLog,
    clearEvidenceLogs
  }
}) 