import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, browserPopupRedirectResolver } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { 
  getStorage, 
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject 
} from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBehRiFYWgTVmPj_8PPq35RYNcTaHb1PEY",
  authDomain: "legalpad-561b3.firebaseapp.com",
  projectId: "legalpad-561b3",
  storageBucket: "legalpad-561b3.firebasestorage.app",
  messagingSenderId: "109403703969",
  appId: "1:109403703969:web:37732254735202b6177ee0",
  measurementId: "G-DNXEMR7RQJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services with updated settings
const auth = getAuth(app);
auth.settings = {
  popupRedirectResolver: browserPopupRedirectResolver
};

const db = getFirestore(app);

// Enable offline persistence with error handling
if (typeof window !== 'undefined') {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support persistence.');
    }
  });
}

const storage = getStorage(app);
const analytics = getAnalytics(app);

// Initialize auth provider with custom settings
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

// Export everything
export {
  app,
  db,
  auth,
  storage,
  analytics,
  googleProvider,
  storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject
};