import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import '@fontsource/source-sans-pro/200.css' // Extra Light
import '@fontsource/source-sans-pro/300.css' // Light
import '@fontsource/source-sans-pro/400.css' // Regular

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          // Main Colors
          background: '#F4FAF8',    // Lightest green for main background
          surface: '#DEE9E6',       // Second lightest green for surfaces
          primary: '#90AB97',       // Second darkest green for buttons
          secondary: '#B8CDC5',     // Mid-tone green
          
          // UI Colors
          'surface-variant': '#DEE9E6',  // Second lightest green
          'on-surface': '#2C3A33',       // Very dark green for text
          'on-background': '#2C3A33',    // Very dark green for text
          
          // Input and Card Colors
          'input-background': '#DEE9E6',  // Second lightest green
          'card-background': '#DEE9E6',   // Second lightest green
          
          // System Colors
          error: '#d32f2f',
          info: '#6E8B7C',         // Darkest green for info
          success: '#6E8B7C',      // Darkest green for success
          warning: '#ffa726'
        },
        variables: {
          'border-color': '#B8CDC5',
          'border-opacity': 0.12,
          'high-emphasis-opacity': 0.87,
          'medium-emphasis-opacity': 0.60,
          'disabled-opacity': 0.38,
          'hover-opacity': 0.08
        }
      }
    },
    defaults: {
      VBtn: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 300,
        letterSpacing: '0.0892857143em',
        textTransform: 'uppercase',
        elevation: 1
      },
      VCard: {
        elevation: 0,
        border: true,
        backgroundColor: '#FFFFFF'
      },
      VTextField: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        backgroundColor: '#FFFFFF'
      },
      VSelect: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        backgroundColor: '#FFFFFF'
      },
      VToolbar: {
        color: '#6E8B7C',
        elevation: 1
      }
    },
    typography: {
      fontFamily: 'Source Sans Pro, sans-serif',
      h1: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '200',
        letterSpacing: '-0.02em',
        lineHeight: '1.2',
        fontSize: '3rem'
      },
      h2: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '200',
        letterSpacing: '-0.01em',
        lineHeight: '1.3',
        fontSize: '2.5rem'
      },
      h3: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0',
        lineHeight: '1.4',
        fontSize: '2rem'
      },
      h4: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0.0073529412em',
        lineHeight: '1.4',
        fontSize: '1.75rem'
      },
      h5: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0',
        lineHeight: '1.5',
        fontSize: '1.5rem'
      },
      h6: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0.0125em',
        lineHeight: '1.5',
        fontSize: '1.25rem'
      },
      subtitle1: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0.009375em',
        lineHeight: '1.5',
        fontSize: '1rem'
      },
      body1: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0.03125em',
        lineHeight: '1.5',
        fontSize: '1rem'
      },
      button: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: '300',
        letterSpacing: '0.0892857143em',
        textTransform: 'uppercase'
      }
    }
  }
}) 