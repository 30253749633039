<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="text-h4 text-center">Checkout</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="handleSubmit" ref="form">
              <v-text-field
                v-model="formData.name"
                label="Full Name"
                required
                :rules="[v => !!v || 'Name is required']"
              ></v-text-field>
              
              <v-text-field
                v-model="formData.email"
                label="Email"
                type="email"
                required
                :rules="[
                  v => !!v || 'Email is required',
                  v => /.+@.+\..+/.test(v) || 'Email must be valid'
                ]"
              ></v-text-field>

              <!-- Placeholder for Stripe Elements -->
              <div class="stripe-placeholder pa-4 mb-4 bg-grey-lighten-4">
                Stripe Payment Form will be integrated here
              </div>

              <v-btn
                type="submit"
                block
                color="primary"
                size="large"
                :loading="loading"
              >
                Complete Purchase
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Success Dialog -->
    <v-dialog v-model="showSuccess" width="500">
      <v-card>
        <v-card-title class="text-h5 text-center">
          Thank You!
        </v-card-title>
        <v-card-text class="text-center">
          Your purchase was successful. You'll receive a confirmation email shortly.
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            @click="navigateToHome"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const form = ref(null)
const loading = ref(false)
const showSuccess = ref(false)

const formData = ref({
  name: '',
  email: '',
})

const handleSubmit = async () => {
  const { valid } = await form.value.validate()
  
  if (valid) {
    loading.value = true
    // Simulate API call
    setTimeout(() => {
      loading.value = false
      showSuccess.value = true
    }, 2000)
  }
}

const navigateToHome = () => {
  showSuccess.value = false
  router.push('/')
}
</script> 