<template>
  <div class="home">
    <!-- Hero Section -->
    <div class="hero-section">
      <v-container class="py-16 content-container">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="7">
            <div class="hero-content">
              <h1 class="text-h2 font-weight-light mb-6">
                Streamline Your <br>
                <span class="highlight-text">Trial Presentations</span>
              </h1>
              
              <p class="text-h5 font-weight-light text-white mb-12">
                Engage judges and jurors, retain attention, and improve trial outcomes with a modern presentation platform built for legal professionals.
              </p>

              <div class="d-flex gap-4">
                <v-btn
                  size="x-large"
                  color="primary"
                  class="px-8 discover-btn"
                  :to="isAuthenticated ? '/library' : '/login'"
                >
                  Get Started Today
                  <v-icon end>mdi-arrow-right</v-icon>
                </v-btn>
                
                <v-btn
                  size="x-large"
                  variant="outlined"
                  color="secondary-lighten-1"
                  class="px-8"
                  to="/features"
                >
                  Explore Features
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Benefits Section -->
    <section class="py-16">
      <v-container class="content-container">
        <v-row justify="center" class="mb-12">
          <v-col cols="12" md="8" class="text-center">
            <h2 class="text-h3 font-weight-bold mb-4">
              Why Legal Teams Choose LegalLogic
            </h2>
            <p class="text-h6 text-medium-emphasis">
              Transform your trial presentations with tools designed for modern legal professionals
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="benefit in benefits" :key="benefit.title" cols="12" md="4">
            <v-card variant="flat" class="benefit-card h-100">
              <v-card-text class="text-center pa-8">
                <v-avatar
                  :color="benefit.color"
                  size="80"
                  class="mb-6"
                >
                  <v-icon
                    :icon="benefit.icon"
                    color="white"
                    size="36"
                  ></v-icon>
                </v-avatar>

                <h3 class="text-h5 font-weight-bold mb-4">{{ benefit.title }}</h3>
                <p class="text-body-1 text-medium-emphasis">{{ benefit.description }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Future Features Section -->
    <section class="py-16 bg-surface">
      <v-container class="content-container">
        <v-row justify="center" class="mb-12">
          <v-col cols="12" md="8" class="text-center">
            <v-chip color="primary" class="mb-4">Coming Soon</v-chip>
            <h2 class="text-h3 font-weight-bold mb-4">
              The Future of Legal Presentations
            </h2>
            <p class="text-h6 text-medium-emphasis">
              Experience the next generation of trial presentation tools
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="feature in futureFeatures" :key="feature.title" cols="12" md="4">
            <v-card class="future-feature-card h-100">
              <v-card-text class="pa-8">
                <div class="d-flex align-center mb-6">
                  <v-avatar
                    :color="feature.color"
                    size="48"
                    class="mr-4"
                  >
                    <v-icon :icon="feature.icon" color="white"></v-icon>
                  </v-avatar>
                  <h3 class="text-h6">{{ feature.title }}</h3>
                </div>
                <p class="text-body-1 text-medium-emphasis">{{ feature.description }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Testimonials Section -->
    <section class="py-16">
      <v-container class="content-container">
        <v-row justify="center" class="mb-12">
          <v-col cols="12" md="8" class="text-center">
            <h2 class="text-h3 font-weight-bold mb-4">
              Trusted by Legal Professionals
            </h2>
            <p class="text-h6 text-medium-emphasis">
              See what our users are saying about LegalLogic
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="testimonial in testimonials" :key="testimonial.name" cols="12" md="4">
            <v-card class="testimonial-card h-100">
              <v-card-text class="pa-8">
                <v-icon color="primary" size="36" class="mb-4">mdi-format-quote-open</v-icon>
                <p class="text-body-1 mb-6">{{ testimonial.quote }}</p>
                <div class="d-flex align-center">
                  <v-avatar color="primary" class="mr-4">
                    <span class="text-h6">{{ testimonial.initials }}</span>
                  </v-avatar>
                  <div>
                    <div class="text-subtitle-1 font-weight-medium">{{ testimonial.name }}</div>
                    <div class="text-caption text-medium-emphasis">{{ testimonial.title }}</div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Features Overview Section -->
    <section class="py-16 bg-surface">
      <v-container class="content-container">
        <v-row justify="center" class="mb-12">
          <v-col cols="12" md="8" class="text-center">
            <h2 class="text-h3 font-weight-bold mb-4">
              Features That Make a Difference
            </h2>
            <p class="text-h6 text-medium-emphasis">
              Everything you need for successful trial presentations
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="feature in features" :key="feature.title" cols="12" md="4">
            <v-hover v-slot="{ isHovering, props }">
              <v-card
                v-bind="props"
                :elevation="isHovering ? 4 : 1"
                class="feature-card h-100"
              >
                <v-card-text class="pa-8">
                  <v-icon
                    :icon="feature.icon"
                    :color="feature.color"
                    size="36"
                    class="mb-4"
                  ></v-icon>
                  <h3 class="text-h6 mb-4">{{ feature.title }}</h3>
                  <p class="text-body-2 text-medium-emphasis">{{ feature.description }}</p>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- CTA Section -->
    <section class="py-16 cta-section">
      <v-container class="content-container">
        <v-row justify="center">
          <v-col cols="12" md="8" class="text-center">
            <h2 class="text-h3 font-weight-light text-white mb-6">
              Ready to Transform Your Trial Presentations?
            </h2>
            <p class="text-h6 text-white-darken-2 mb-12">
              Join thousands of legal professionals who trust LegalLogic
            </p>
            <div class="d-flex justify-center gap-4">
              <v-btn
                color="primary"
                size="x-large"
                class="px-8"
                :to="isAuthenticated ? '/library' : '/login'"
              >
                Try LegalLogic Free
              </v-btn>
              <v-btn
                variant="outlined"
                color="secondary"
                size="x-large"
                class="px-8"
                to="/features"
              >
                Learn More
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script setup>
import { useAuth } from '../stores/auth'

const { isAuthenticated } = useAuth()

const benefits = [
  {
    title: 'Attention Retention',
    description: 'Keep judges and jurors engaged with clear, concise presentations that maintain focus on key evidence.',
    icon: 'mdi-eye-check',
    color: 'primary'
  },
  {
    title: 'Better Outcomes',
    description: 'Improve your chances of success with organized and impactful evidence delivery that resonates.',
    icon: 'mdi-target',
    color: 'success'
  },
  {
    title: 'Maximum Efficiency',
    description: 'Save valuable time with tools that streamline your preparation and presentation process.',
    icon: 'mdi-rocket-launch',
    color: 'info'
  }
]

const futureFeatures = [
  {
    title: 'AI-Powered Insights',
    description: 'Leverage ChatGPT integration for intelligent case analysis and presentation suggestions.',
    icon: 'mdi-brain',
    color: 'secondary'
  },
  {
    title: 'Real-time Collaboration',
    description: 'Work seamlessly with your team, share insights, and make updates in real-time.',
    icon: 'mdi-account-group',
    color: 'info'
  },
  {
    title: 'Advanced Analytics',
    description: 'Gain valuable insights into presentation effectiveness and jury engagement.',
    icon: 'mdi-chart-bar',
    color: 'success'
  }
]

const testimonials = [
  {
    quote: 'LegalLogic transformed how we present evidence. The jury was more engaged, and we saw better outcomes.',
    name: 'Sarah Mitchell',
    title: 'Senior Partner, Mitchell & Associates',
    initials: 'SM'
  },
  {
    quote: "The platform's intuitive design and powerful features have become indispensable to our trial preparation.",
    name: 'James Wilson',
    title: 'Trial Attorney',
    initials: 'JW'
  },
  {
    quote: 'Finally, a presentation tool that understands the unique needs of legal professionals.',
    name: 'David Chen',
    title: 'Legal Technology Director',
    initials: 'DC'
  }
]

const features = [
  {
    title: 'Dynamic Presentation Tools',
    description: 'Present evidence dynamically with intuitive controls and smooth transitions.',
    icon: 'mdi-presentation',
    color: 'primary'
  },
  {
    title: 'Smart Organization',
    description: 'Keep all case materials organized and instantly accessible during trial.',
    icon: 'mdi-folder-multiple',
    color: 'secondary'
  },
  {
    title: 'Secure Data Management',
    description: 'Enterprise-grade security ensures your sensitive case data stays protected.',
    icon: 'mdi-shield-check',
    color: 'success'
  }
]
</script>

<style scoped>
.hero-section {
  background-image: url('/images/homepage_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  height: 80vh;
  max-height: 800px;
  min-height: 600px;
  display: flex;
  align-items: center;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(15, 40, 48, 0.95),
    rgba(1, 71, 81, 0.85)
  );
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
}

.highlight-text {
  color: #00D37F;
  font-weight: 300;
}

.benefit-card {
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-8px);
}

.future-feature-card {
  border-radius: 16px;
  transition: all 0.3s ease;
}

.future-feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.testimonial-card {
  border-radius: 16px;
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-4px);
}

.feature-card {
  border-radius: 16px;
  transition: all 0.3s ease;
}

.cta-section {
  background: linear-gradient(
    145deg,
    rgba(1, 71, 81, 0.95) 0%,
    rgba(15, 40, 48, 0.95) 100%
  );
  position: relative;
}

.gap-4 {
  gap: 1rem;
}

/* Update responsive text sizes */
@media (max-width: 960px) {
  .text-h2 {
    font-size: 2.5rem !important;
    font-weight: 300 !important;
  }

  .text-h5 {
    font-size: 1.25rem !important;
    font-weight: 300 !important;
  }
}

@media (max-width: 600px) {
  .text-h2 {
    font-size: 2rem !important;
    font-weight: 300 !important;
  }
  
  .text-h5 {
    font-size: 1.1rem !important;
    font-weight: 300 !important;
  }
}

/* Update height-based responsive styles */
@media (max-height: 800px) {
  .hero-section .text-h2 {
    font-size: 2.75rem !important;
    font-weight: 300 !important;
  }
  
  .hero-section .text-h5 {
    font-size: 1.2rem !important;
    font-weight: 300 !important;
  }
}

@media (max-height: 700px) {
  .hero-section .text-h2 {
    font-size: 2.25rem !important;
    font-weight: 300 !important;
  }
  
  .hero-section .text-h5 {
    font-size: 1.1rem !important;
    font-weight: 300 !important;
  }
  
  .hero-section .mb-12 {
    margin-bottom: 24px !important;
  }
  
  .hero-section .mb-6 {
    margin-bottom: 16px !important;
  }
}

/* Add before other styles */
.content-container {
  max-width: 1200px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

@media (max-width: 600px) {
  .content-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
</style> 